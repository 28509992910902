

export const GetObjectValues = (data: any[], value: string) => {
    return data.map(item => {
        return item[value];
    })
}

export const TranslateToOptions = (label: string, value: string, fetchResult?: any[]) =>  {
    if(!fetchResult){
        return [];
    }
    return fetchResult.map(item => {
        return { 'label': item[label], 'value': item[value] };
    })
}