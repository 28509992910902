import React, {useState} from "react";
import Input from "antd/es/input/Input";
import "./LoginView.scss";
import Password from "antd/es/input/Password";
import {Form} from "antd";
import fetcher, {fetcherSaveToken} from "../../tools/Fetcher";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHatCowboy} from "@fortawesome/free-solid-svg-icons/faHatCowboy";


export interface ILoginViewProps{
    saveToken: Function
}

const LoginView = ({saveToken}: ILoginViewProps) => {
    const [form] = Form.useForm();
    const [loginError, setLoginError] = useState<string>("");

    const handleSubmit = () => {

        fetcher('/auth', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(form.getFieldsValue())
        }, true)
            .then((response: Response) => {
                if(response.status == 200){
                    response.json().then(result => {
                        const token = result["token"];
                        saveToken(token);
                        fetcherSaveToken(token);
                    })
                    setLoginError("");
                }
                else{
                    if(response.statusText == "Locked"){
                        setLoginError("Ucet je zablokovan");
                        return;
                    }
                    setLoginError("Neplatny login nebo heslo");
                }
            });
    }

    return (
        <div className="login-view">
            <div className={"login-container"}>
                <div className={"login-header"}>
                    <FontAwesomeIcon icon={faHatCowboy} /> HatMIN - TM

                </div>
                <div className={"login-content"}>
                    <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }}>
                        <Form.Item label={"Login"} name={"login"}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={"Heslo"} name={"password"}>
                            <Password />
                        </Form.Item>

                        <button type="button" onClick={() => handleSubmit()}>Prihlasit</button>
                    </Form>

                    <div className={"login-error"}>
                        {loginError}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginView;