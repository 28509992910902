import AdvancedLabel, {AdvancedLabelTypeEnum} from "./common/AdvancedLabel";
import {useEffect, useState} from "react";
import fetcher from "../../../../../v2/tools/Fetcher";
import {AdvancedCheckbox} from "./common/AdvancedCheckbox";

interface StatisticsResponse{
    orderCount: number,
    orderPrice: number,
    eshopCount: number,
    eshopPrice: number
}

const StatisticsDashboardComponent = () => {
    const [statisticsResponse, setStatisticsResponse] = useState<StatisticsResponse>();
    const [thisMonth, setThisMonth] = useState<boolean>(true);

    useEffect(() => {
        let url = "/statistics";
        if(thisMonth){
            url = `/statistics/month/${new Date().getMonth()}`;
        }

        fetcher(url)
            .then(response => response.json())
            .then(result => {
                setStatisticsResponse(result);
            })

    }, [thisMonth]);

    const handleChangeMonth = () => {
        setThisMonth(!thisMonth);
    }

    return (
        <div className={"reservation-dashboard-component-item"}>

            <AdvancedCheckbox label={"Pouze tento mesic"} value={thisMonth} updateFunction={handleChangeMonth} />
            <div className={"reservation-dashboard-component-info"}>
                <AdvancedLabel label={"Pocet objednavek"} value={statisticsResponse?.orderCount.toString()} valueType={AdvancedLabelTypeEnum.Number}/>
                <AdvancedLabel label={"Vydelek za objednavky"} value={statisticsResponse?.orderPrice.toString()} valueType={AdvancedLabelTypeEnum.Number} suffix={" KC"}/>
                <AdvancedLabel label={"Pocet polozek eshopu"} value={statisticsResponse?.eshopCount.toString()} valueType={AdvancedLabelTypeEnum.Number}/>
                <AdvancedLabel label={"Vydelek za eshop"} value={statisticsResponse?.eshopPrice.toString()} valueType={AdvancedLabelTypeEnum.Number} suffix={" KC"}/>
            </div>


        </div>
    )
}

export default StatisticsDashboardComponent;