
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

export interface INavigationItemProps {
    link: string,
    text: string
}

export interface INavigationMenuItemProps{
    text: string,
    link?: string,
    items?: NavigationMenuNode[]

}

export interface INavigationMenuProps{
    navigationMenuNodes: NavigationMenuNode[]
    headerText?: string
    headerVersion?: string
    previewFunction?: Function
}

export class NavigationMenuNode{
    navigationMenuNodes?: NavigationMenuNode[] = [];
    text: string = ""
    link?: string = ""
    icon?: IconDefinition

    public constructor(init?:Partial<NavigationMenuNode>) {
        Object.assign(this, init);
    }
}
