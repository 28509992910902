import React, {ReactNode, useMemo, useState} from 'react';
import {Checkbox, DatePicker, Form, Input, InputNumber, Modal, Table} from 'antd';
import {LoadingBar} from './LoadingBar';
import "./BaseAdminView.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPenToSquare, faPlus, faRotateRight, faTrash} from '@fortawesome/free-solid-svg-icons'
import ContentGallery, {ContentModel} from './ContentGallery';
import {FormComplexField} from './baseAdminComponents/FormComplexField';
import {AdminSelect} from './baseAdminComponents/AdminSelect';
import {
    AdminTemplate,
    AntdTableColumn,
    ColumnType,
    ConditionFilter,
    CustomControlItem,
    Editable,
    ICustomAction,
    ICustomControlItem,
    StandardAction
} from './BaseAdminViewModels';
import 'dayjs/locale/cs';
import locale from 'antd/es/date-picker/locale/cs_CZ'
import dayjs from 'dayjs';
import fetcher from "../../../tools/Fetcher";
import HtmlEditor from "./lexicalEditor/HtmlEditor";
import MultiRangeSelector from "./baseAdminComponents/MultiRangeSelector";

const { TextArea } = Input;

type BaseAdminViewProps = {
    template: AdminTemplate,
    testString?: string
}


type ModalColumnProps = {
    column: AntdTableColumn,
    data: any,
    updateData: Function
}

const GetImagePath = (path: string) => {
    if (!path) {
        return;
    }
    if (path.startsWith("images/")) {
        return path;
    }
    return `images/${path}`;
}

const ModalColumn = ({ column, data, updateData }: ModalColumnProps) => {
    const [showImageGallery, setShowImageGallery] = useState<boolean>(false);

    const selectContentItem = (value: ContentModel, confirmToken: boolean) => {
        setShowImageGallery(false);
        if (!confirmToken) {
            return;
        }

        updateData(column.key, value.getImagePath());
    }


    const renderImage = () => {
        if (showImageGallery) {
            return (
                <div>
                    <ContentGallery selectContent={(value: ContentModel, confirmToken: boolean) => selectContentItem(value, confirmToken)} />
                </div>
            )
        }

        if (data[column.key]) {
            return (<>
                <img alt="modal-img" className="modal-image" src={GetImagePath(data[column.key])} />
                <div>
                    <button className='btn' onClick={() => setShowImageGallery(true)}>Zmenit obrazek</button>
                </div>
            </>
            )
        }

        return (
            <div>
                <button className='btn' onClick={() => setShowImageGallery(true)}>Vybrat obrazek</button>
            </div>
        )

    }

    if (column.key == "action") {
        return (<></>)
    }

    if (column.hiddenInput) {
        return (<Form.Item label={column.title} name={column.key} hidden={true}>

        </Form.Item>)
    }

    let content: ReactNode;
    let formItemProps: any = {};
    let useFormItem = true;
    const customProps: any = column.componentCustomProps;

    if (!column.editable){
        customProps["disabled"] = true;
    }

    if (column.infoHelper) {
        formItemProps["extra"] = column.infoHelper;
    }


    if (column.columnType == ColumnType.Text) {
        content = (
            <Input placeholder={column.title} {...customProps} />
        )
    }

    else if (column.columnType == ColumnType.TextArea) {
        content = (
            <TextArea placeholder={column.title} {...customProps} />
        )
    }

    else if (column.columnType == ColumnType.Image) {
        content = renderImage();
    }

    else if (column.columnType == ColumnType.Checkbox) {
        content = (
            <Checkbox {...column.componentCustomProps} />
        )

        formItemProps["valuePropName"] = "checked";
    }

    else if (column.columnType == ColumnType.Number) {
        content = (<InputNumber placeholder={column.title} {...customProps} />)
    }

    else if (column.columnType == ColumnType.DateTime) {
        content = (<DatePicker placeholder={column.title} {...column.componentCustomProps} locale={locale} />)
    }

    else if (column.columnType == ColumnType.Select) {
        content = (
            <AdminSelect columnTemplate={column} data={data} updateData={updateData} />
        )
    }
    else if (column.columnType === ColumnType.FormComplexField) {
        content = (
            <FormComplexField data={data} columnTemplate={column} updateData={updateData} />
        )
    }
    else if (column.columnType === ColumnType.MultiRangeSelector){
        content = (
            <MultiRangeSelector dataKey={column.key} updateData={updateData} data={data} />
        )
    }
    else if(column.columnType === ColumnType.Html){
        content = (
            <HtmlEditor key={data[column.key]} updateData={updateData} initialHtml={data[column.key]} dataKey={column.key} />
        )
    }


    if (useFormItem) {
        return (
            <div>
                <Form.Item key={column.key} label={column.title} name={column.key} {...formItemProps}>
                    {content}
                </Form.Item>
            </div>
        )
    }

    return (
        <div>
            {content}
        </div>
    )
}


enum ModalMode {
    Create = 0,
    Edit = 1
}

const BaseAdminView = ({ template, testString }: BaseAdminViewProps) => {
    const [getData, setGetData] = useState([]);
    const [modal, contextHolder] = Modal.useModal();
    const [recordToDelete, setRecordToDelete] = useState<any|undefined>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCustomControlsModal, setShowCustomControlsModal] = useState<boolean>(false);
    const [editData, setEditData] = useState<any>([]);
    const [form] = Form.useForm();
    const [customContorlsForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Create);
    const [activeCustomControlItem, setActiveCustomControlItem] = useState<ICustomControlItem>();
    const [searchText, setSearchText] = useState<string>("");
    const [conditionFilter, setConditionFilter] = useState<string>("");
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>();

    const sanitizeData = (data: any) => {
        if (!data){
            return;
        }

        data.forEach((item: any) => {
            template.table.columns.forEach((column: AntdTableColumn) => {
                if (column.columnType == ColumnType.DateTime) {
                    if (item[column.key]) {
                        item[column.key] = dayjs(new Date(item[column.key]));
                    }
                }
            })
        })
    }

    const refreshData = (search: string = "", conditionalFilter: string = "") => {
        if(searchTimeout){
            clearTimeout(searchTimeout);
            setSearchTimeout(undefined);
        }
        setIsLoading(true);

        let url = template.getUrl;

        let alreadyQueryParam = false;

        if (search) {
            alreadyQueryParam = true;
            url = `${url}?search=${search}`;
        }
        if (conditionalFilter){
            let prefix = alreadyQueryParam ? "&" : "?";
            url = `${url}${prefix}conditionalFilter=${conditionalFilter}`
        }

        fetcher(url, undefined)
            .then(response => response.json())
            .then(result => {
                sanitizeData(result);
                setGetData(result);
                setIsLoading(false);
            })
    }

    const data = useMemo(() =>
        refreshData()
        , [setGetData]
    )

    let content: ReactNode;
    const updateData = () => {
        let method: string = "";
        let url: string = "";

        if (modalMode == ModalMode.Create) {
            method = "POST";
            url = template.createUrl;
        }
        else if (modalMode == ModalMode.Edit) {
            method = "PUT";
            url = template.updateUrl;
        }

        // console.log(form.getFieldsValue());

        setIsLoading(true);
        fetcher(url, {
            method: method,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(form.getFieldsValue())
        })
            .then(response => {
                setIsLoading(false)
                refreshData();
            });

        setShowModal(false);

    }

    const deleteData = () => {
        setIsLoading(true);

        const id = getPrimaryValueOfData(recordToDelete);

        fetcher(`${template.deleteUrl}/${id}`, {
            method: 'DELETE'
        })
        .then(response => {
            setIsLoading(false);
            refreshData();
        })

        setRecordToDelete(undefined);
    }

    const getPrimaryValueOfData = (data: any) => {
        if(!data){
            return;
        }
        const key = template.getPrmirayKey();
        if(!key){
            return;
        }

        return data[key];
    }

    const editRecord = (record: any) => {
        // console.log("editRecord");
        // console.log(record);
        setShowModal(true);
        setEditData(record);

        const { columns } = template.table;

        var formData: any = {}

        columns.forEach(column => {
            formData[column.key] = record[column.key]
        });


        //console.log(formData);
        setModalMode(ModalMode.Edit);

        form.setFieldsValue(formData);
    }

    const deleteRecord = (record: any) => {
        setRecordToDelete(record);
    }


    const createNew = () => {
        //console.log("Create new");
        form.resetFields();
        setEditData([]);
        setModalMode(ModalMode.Create);
        setShowModal(true);
    }

    const updateModalData = (key: string, value: any) => {
        // console.log("UPDATE MODAL DATA");
        // console.log(key);
        // console.log(value);

        form.setFieldValue(key, value);
        setEditData({
            ...editData,
            [key]: value
        })
    }

    const { columns } = template.table;

    const renderColumns = () => {
        if (!columns) {
            return <></>
        }
        if (isLoading) {
            return <LoadingBar show={true} />
        }

        return (
            <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }}>
                {columns.map((column: AntdTableColumn) => {
                    return <ModalColumn column={column} data={editData} updateData={(key: string, value: any) => updateModalData(key, value)} />
                })}
            </Form>
        )
    }

    const renderColumnActions = (record: any) => {
        return (
            <div className={"base-admin-view-table-controls"}>
                {template.editable.includes(Editable.Edit)  &&
                    <button title="Editovat" className='btn' onClick={() => editRecord(record)}>
                        <FontAwesomeIcon icon={faPenToSquare}/>
                    </button>
                }
                {template.editable.includes(Editable.Delete) &&
                    <button title="Editovat" className='btn' onClick={() => deleteRecord(record)}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </button>
                }
                {template.customActions.length > 0 &&
                    template.customActions.map((action: ICustomAction) => {
                        return <button title={action.buttonTitle} className={'btn'}
                                       onClick={() => action.executeFunction(record)}>{action.buttonContent}</button>
                    })
                }
            </div>
        )
    }


    const updateColumnRenderer = (columns: AntdTableColumn[]) => {
        columns.forEach(column => {
            if (column.render) {
                return;
            }

            if (column.tableHidden) {
                column.render = (_: any, record: any) => {
                    return <div></div>
                }
            }

            if (column.columnType === ColumnType.Image) {
                column.render = (_: any, record: any) => {
                    return <img className='column-image' src={GetImagePath(record[column.key])}/>
                }
            } else if (column.columnType === ColumnType.Checkbox) {
                column.render = (_: any, record: any) => {
                    return <div>{record[column.key] ? "ANO" : "NE"}</div>
                }
            }
            else if(column.columnType === ColumnType.Select){
                column.render = (_:any, record: any) => {
                    if(!record[column.key]){
                        return <div>-</div>
                    }
                    return <div>{record[column.key][column.selectMapLabel]}</div>
                }
            }
            else if (column.columnType === ColumnType.Object) {
                column.render = (_: any, record: any) => {
                    if (column.nestedObject) {
                        return <div>{record[column.key][column.objectKey][column.nestedObject.key]}</div>
                    }
                    return <div>{record[column.key][column.objectKey]}</div>
                }
            }
            else if (column.columnType === ColumnType.DateTime) {
                column.render = (_: any, record: any) => {
                    if(!record[column.key]){
                        return <div>-</div>
                    }
                    return <div>{new Date(record[column.key]).toLocaleString()}</div>
                }
            }
        });


        if ((template.editable.includes(Editable.Edit) || template.editable.includes(Editable.Delete)) &&
            !columns.find(column => column.key === "action")) {

            columns.push(
                new AntdTableColumn({
                    title: "Akce",
                    key: "action",
                    render: (_: any, record: any) => (
                        renderColumnActions(record)
                    ),
                    hiddenInput: false
                }))
        }
        return columns;
    }

    if (template.table != null) {
        const updatedColumns = updateColumnRenderer(template.table.columns);
        const filteredColumns = updatedColumns.filter((column) => !column.tableHidden);

        content = <Table dataSource={getData} columns={filteredColumns} />
    }

    const handleCustomControlModalClose = () => {
        setShowCustomControlsModal(false);

        if( activeCustomControlItem?.actionAfterExecution === StandardAction.RefreshData){
            refreshData();
        }
        setActiveCustomControlItem(undefined);
    }

    const renderCustomControlsContent = () => {
        if(!activeCustomControlItem) {
            return <></>
        }

        return(
            <Modal
                open={showCustomControlsModal}
                onCancel={() => handleCustomControlModalClose()}
                onOk={() => handleCustomControlModalClose()}
            >
                {activeCustomControlItem.content}
            </Modal>
        )

    }

    const handleCustomControlsClick = (item: ICustomControlItem) => {
        setActiveCustomControlItem(item)
        setShowCustomControlsModal(true);
    }

    const renderCustomControlsButton = (item: ICustomControlItem) => {
        let content: any;

        if(item.controlType === CustomControlItem.Button){
            content = <button className={"btn"} onClick={() => handleCustomControlsClick(item)}>{item.label}</button>
        }

        return <div className={"base-admin-custom-control-item"}>
            {content}
        </div>
    }

    const renderCustomControls = () => {
        if(!template.customControls){
            return <></>
        }
        return <div className={"base-admin-custom-controls"}>
            {template.customControls.controlItems.map((item: ICustomControlItem) => {
                return renderCustomControlsButton(item)
            })}
        </div>
    }

    const renderCreateButton = () => {
        if (!template.editable.includes(Editable.Create))
            return <></>;

        return <button className='btn' onClick={() => createNew()}><FontAwesomeIcon icon={faPlus} /> Pridat</button>;
    }

    const renderRefreshButton = () => {
        return (
            <div className={"base-admin-controls-refresh"}>
                <button className={"btn"} onClick={() => refreshData(searchText, conditionFilter)}><FontAwesomeIcon icon={faRotateRight}/> </button>
            </div>
        )
    }

    const updateSearchBar = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setSearchText(value);
        console.log(value);

        if(searchTimeout){
            clearTimeout(searchTimeout);
        }

        setSearchTimeout(setTimeout(() => {
            refreshData(value, conditionFilter);
        }, 500));
    }

    const updateConditionFilter = (value: string) => {
        let filterValue = value;
        if(conditionFilter == filterValue){
            setConditionFilter("");
            filterValue = "";
        }
        else {
            setConditionFilter(filterValue);
        }
        refreshData(searchText, filterValue);
    }

    const renderFilterConditions = () => {
        if(!template.conditionFilters){
            return <></>;
        }

        return <div className={"base-admin-condition-filter"}>
            {template.conditionFilters.map((item: ConditionFilter) => {
                let itemClass = "base-admin-condition-filter-item ";
                if(item.value == conditionFilter){
                    itemClass += "active";
                }
                return <div className={itemClass}>
                    <button type={"button"} className={"btn"} onClick={() => updateConditionFilter(item.value)}>{item.name}</button>
                </div>
            })}
        </div>
    }

    const renderSearchBar = () => {
        if (!template.searchable){
            return <></>;
        }

        return <div className={"base-admin-searchbar"}>
            <label>Hledat</label>
            <input value={searchText} onChange={updateSearchBar}/>
        </div>
    }

    if(testString === "preview"){
        if(showModal){
            return (
                <div className='admin-wrapper'>
                    {renderColumns()}
                </div>
            )
        }

        return (
            <div className='admin-wrapper'>
                {renderCustomControlsContent()}
                <div className='header'>
                    <h2>{template.headerText}</h2>
                </div>
                <div className='view-control-panel'>
                    {renderCreateButton()}
                    {renderRefreshButton()}
                    {renderCustomControls()}
                    {renderFilterConditions()}
                    {renderSearchBar()}
                </div>
                <div className='content'>
                    {isLoading &&
                        <div className={"admin-loading-container"}>
                            <LoadingBar show={true} />
                        </div>
                    }
                    {content}s
                </div>
                {contextHolder}
            </div>
        )
    }

    return (
        <div className='admin-wrapper'>
            <Modal open={showModal} onCancel={() => setShowModal(false)} onOk={() => updateData()} maskClosable={false}>
                {renderColumns()}
            </Modal>
            <Modal open={recordToDelete} onCancel={() => setRecordToDelete(undefined)} onOk={() => deleteData()} >
                Smazat {getPrimaryValueOfData(recordToDelete)}
            </Modal>
            {renderCustomControlsContent()}
            <div className='header'>
                <h2>{template.headerText}</h2>
            </div>
            <div className='view-control-panel'>
                {renderCreateButton()}
                {renderRefreshButton()}
                {renderCustomControls()}
                {renderFilterConditions()}
                {renderSearchBar()}
            </div>
            <div className='content'>
                {isLoading &&
                    <div className={"admin-loading-container"}>
                        <LoadingBar show={true} />
                    </div>
                }
                {content}
            </div>
            {contextHolder}
        </div>
    );
}

export default BaseAdminView;
