import {IContent, IContentDynamic, IContentItemProps} from "./models/ContentModels";
import {ChangeEvent, useEffect, useState} from "react";
import fetcher from "../../../tools/Fetcher";
import {Input} from "antd";
import "./ContentEditor.scss";
import Editor from "./lexicalEditor/LexicalEditor";


const ContentItem = ({content, updateData}: IContentItemProps) => {
    const [changed, setChanged] = useState<boolean>(false)

    const handleUpdateData = (element: ChangeEvent<HTMLInputElement>) =>{
        const {value} = element.target;

        setChanged(true);
        updateData(content.key, value);
    }

    const handleSubmit = () => {
        setChanged(false);
        fetcher('/content', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(content)
        })
            .then(response => {
                console.log("OK");
            })
    }

    return <div className={"content-editor-item"}>
        <div className={"content-editor-item-name"}>{content.name}</div>
        <Input value={content.value} onChange={(element) => handleUpdateData(element)}/>
        <div className={"content-editor-item-button"}>
            <button className={"btn"} onClick={handleSubmit} disabled={!changed}>Potvrdit zmenu</button>
        </div>
    </div>
}


export const ContentNewsEditor = () => {
    const [header, setHeader] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [changed, setChanged] = useState<boolean>(false);
    const [newsContent, setNewsContent] = useState<IContentDynamic>();
    const [newsLoaded, setNewsLoaded] = useState<boolean>();


    const handleChangeHeader = (element: ChangeEvent<HTMLInputElement>) => {
        const { value } = element.target;
        setHeader(value);
        setNewsContent({
            ...newsContent,
            name: value
        });
        setChanged(true);
    }

    const handleChangeContent = (html: string) => {
        setContent(html);
        setNewsContent({
            ...newsContent,
            value: html
        });
        setChanged(true);
    }

    useEffect(() => {
        fetcher('/content/news/1')
            .then(response => response.json())
            .then(result => {
                setNewsContent(result);
                setNewsLoaded(true);
            })
    }, []);

    const handleSubmit = () => {
        setChanged(false);
        fetcher('/content/news', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newsContent)
        })
            .then(response => {
                console.log("OK");
            })
    }

    return (
        <div className={"content-editor-news"}>
            <h2>Novinky</h2>
            <div className={"content-editor-item"}>
                <div className={"content-editor-item-name"}>Nadpis</div>
                <Input value={newsContent?.name} onChange={(element) => handleChangeHeader(element)}/>
            </div>
            <div className={"content-editor-wrapper"}>
                {newsLoaded && <Editor handleHtmlOnChange={handleChangeContent} initialHtml={newsContent?.value} />}
            </div>
            <div className={"content-editor-item-button"}>
                <button className={"btn"} onClick={handleSubmit} disabled={!changed}>Potvrdit zmenu</button>
            </div>
        </div>
    )
}

export const ContentEditor = () => {
    const [data, setData] = useState<IContent[]>([]);

    useEffect(() => {
        fetcher('/content')
            .then(response => response.json())
            .then(result => {
                const content: IContent[] = result;
                setData(content);
            })
    }, []);

    const handleUpdateData = (key: string, value: string) => {
        setData(data.map(item => {
            if(item.key === key){
                item.value = value;
            }
            return item;
        }));
    }

    return <div className={"content-editor"}>
        {data.map(item => {
            return <ContentItem content={item} updateData={handleUpdateData} />
        })}
    </div>
}