import React, {useEffect, useState} from 'react';
import ProductAdminView from './views/admin/ProductsAdminView';
import NavigationMenu from './common/NavigationMenu';
import {NavigationMenuNode} from './common/NavigationMenuModels';
import TimeSlotAdminView from './views/admin/TimeSlotAdminView';
import PersonAdminView from './views/admin/PersonAdminView';
import OrderAdminView from './views/admin/OrderAdminView';
import SettingsAdminView from './views/admin/SettingsAdminView';
import PersonRankAdminView from './views/admin/PersonRankAdminView';
import TestAdminView from './views/admin/TestAdminView';
import ConsumerAdminView from "./views/admin/ConsumerAdminView";
import VoucherAdminView from "./views/admin/VoucherAdminView";
import VoucherTypeAdminView from "./views/admin/VoucherTypeAdminView";
import ReservationAdminView from './views/admin/ReservationAdminView';
import LoginView from "./views/admin/LoginView";
import ContentAdminView from "./views/admin/ContentAdminView";
import DashboardAdminView from "./views/admin/DashboardAdminView";
import ShopItemAdminView from "./views/admin/ShopItemAdminView";
import ContentGalleryAdminView from "./views/admin/ContentGalleryAdminView";
import Cookies from 'js-cookie';
import SideNavigationMenu from "./common/SideNavigationMenu";
import "./Admin.scss"
import PhotoGalleryAdminView from "./views/admin/PhotoGalleryAdminView";
import WorkshopsAdminView from "./views/admin/WorkshopsAdminView";
import UserAdminView from "./views/admin/UserAdminView";
import {faBan, faUsers, faGear, faImage, faCamera} from '@fortawesome/free-solid-svg-icons'
import {faRankingStar} from "@fortawesome/free-solid-svg-icons/faRankingStar";
import {faShop} from "@fortawesome/free-solid-svg-icons/faShop";
import {faHammer} from "@fortawesome/free-solid-svg-icons/faHammer";
import {faGauge} from "@fortawesome/free-solid-svg-icons/faGauge";
import {faFolderTree} from "@fortawesome/free-solid-svg-icons/faFolderTree";
import {faTicket} from "@fortawesome/free-solid-svg-icons/faTicket";
import {faPerson} from "@fortawesome/free-solid-svg-icons/faPerson";
import {faCubes} from "@fortawesome/free-solid-svg-icons/faCubes";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import {faUserNurse} from "@fortawesome/free-solid-svg-icons/faUserNurse";
import {faCalendar} from "@fortawesome/free-solid-svg-icons/faCalendar";
import {faSpa} from "@fortawesome/free-solid-svg-icons/faSpa";

type AdminProps = {
    target?: string
}

const GetCurrentView = (target?: string) => {
    if (target === undefined) {
        return <DashboardAdminView />
    }
    if(target === "workshops") {
        return <WorkshopsAdminView />
    }
    if (target === "timeslots") {
        return <TimeSlotAdminView />
    }
    if (target === "people") {
        return <PersonAdminView />
    }
    if (target === "reservations") {
        return <ReservationAdminView />
    }
    if (target === "orders") {
        return <OrderAdminView />
    }
    if (target === "settings") {
        return <SettingsAdminView />
    }
    if (target === "peopleRanks"){
        return <PersonRankAdminView />
    }
    if (target === "consumers"){
        return <ConsumerAdminView />
    }
    if (target === "vouchers"){
        return <VoucherAdminView />
    }
    if (target === "voucherTypes"){
        return <VoucherTypeAdminView />
    }
    if (target === "reservations"){
        return <ReservationAdminView />
    }
    if (target === "contents"){
        return <ContentAdminView />
    }
    if (target === "dashboard"){
        return <DashboardAdminView />
    }
    if (target === "eshop"){
        return <ShopItemAdminView />
    }
    if (target === "contentGallery"){
        return <ContentGalleryAdminView />
    }
    if (target === "photoGallery"){
        return <PhotoGalleryAdminView />
    }
    if (target === "users"){
        return <UserAdminView />
    }
    if (target === "test"){
        return <TestAdminView />
    }
    return <ProductAdminView />
}

const GetNavigationMenuNodes = () => {
    return [
        new NavigationMenuNode({text: "Produkty", link: "/products", icon: faSpa}),
        new NavigationMenuNode({text: "Casy", link: "/timeslots", icon: faCalendar}),
        new NavigationMenuNode({text: "Cloveci", link: "/people", icon: faUserNurse}),
        new NavigationMenuNode({text: "Rezervace", link: "/reservations", icon: faCalendarCheck}),
        new NavigationMenuNode({text: "Objednavky", link: "/orders", icon: faCubes}),
        new NavigationMenuNode({text: "Zakaznici", link: "/consumers", icon: faPerson}),
        new NavigationMenuNode({text: "Vouchery", link: "/vouchers", icon: faTicket}),
        new NavigationMenuNode({text: "Typy Voucheru", link: "/voucherTypes", icon: faTicket}),
        new NavigationMenuNode({text: "Obsah", link: "/contents", icon: faFolderTree}),
        new NavigationMenuNode({text: "Eshop", link: "/eshop", icon: faShop}),
        new NavigationMenuNode({text: "Workshopy", link: "/workshops", icon: faHammer}),
        new NavigationMenuNode({text: "Deska", link: "/dashboard", icon: faGauge}),
        new NavigationMenuNode({text: "Hodnosti", link: "/peopleRanks", icon: faRankingStar}),
        new NavigationMenuNode({text: "Fotogalerie", link: "/photoGallery", icon: faCamera}),
        new NavigationMenuNode({text: "Galerie obsahu", link: "/contentGallery", icon: faImage}),
        new NavigationMenuNode({text: "Nastaveni", link: "/settings", icon: faGear}),
        new NavigationMenuNode({text: "Uzivatele", link: "/users", icon: faUsers}),
        new NavigationMenuNode({text: "Test", link: "/test", icon: faBan })
    ];
}

const Admin = ({ target }: AdminProps) => {
    const [token, setToken] = useState<string>("");
    const [preview, setPreview] = useState<boolean>(true);
    const [collapsedMenu, setCollapsedMenu] = useState<boolean>(false);
    const version = "0.6.1";

    useEffect(() => {
        const prw = Cookies.get("preview")
        if (prw === "true"){
            setPreview(true)
            return
        }
        setPreview(false)
    }, []);

    console.log(`Admin page ${target}`)

    const setPreviewMode = (set: boolean) => {
        setPreview(set)
        Cookies.set("preview", set ? "true" : "false")
    }

    if(!token){
        return <LoginView saveToken={(val: string) => setToken(val)}/>
    }

    const setCollapse = (value: boolean) => {
        setCollapsedMenu(value);
    }

    const getSideLayoutContent = () => {
        return collapsedMenu ? "side-layout-content collapsed" : "side-layout-content"
    }

    const getContent = () => {
        if (!preview) {
            return (
                <div>
                    <NavigationMenu
                            previewFunction={() => setPreviewMode(true)}
                            headerText='TM - ADMIN'
                            navigationMenuNodes={GetNavigationMenuNodes()}
                            headerVersion={version}
                    />
                    <div className={"layout-content"}>
                        {GetCurrentView(target)}
                    </div>
                </div>
            )
        }
        return (
            <div className={"side-layout"}>
                <SideNavigationMenu
                    collapsedFunction={setCollapse}
                    collapsedMenu={collapsedMenu}
                    headerText='HatMIN'
                    navigationMenuNodes={GetNavigationMenuNodes()}
                    headerVersion={version}
                />
                <div className={getSideLayoutContent()}>
                    {GetCurrentView(target)}
                </div>
            </div>
        )
    }

    const getViewsClasses = () => {
        if (!preview) {
            return "views"
        }
        return "views side"
    }

    return (
        <>
            {getContent()}
        </>
    );
}

export default Admin;
