import React, { ReactNode } from 'react';
import { GetText } from '../../tools/GetText';
import { Table, Modal } from 'antd';
import BaseAdminView from './common/BaseAdminView'
import { AdminTemplate, AntdTable, AntdTableColumn, Editable } from './common/BaseAdminViewModels';



function SettingsAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "settingsId",
                key: "settingsId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Klic",
                dataIndex: "key",
                key: "key",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Popis",
                dataIndex: "description",
                key: "description",

                editable: true,
            }),
            new AntdTableColumn({
                title: "Hodnota",
                dataIndex: "value",
                key: "value",

                editable: true,
            })


        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/setting';
    dataTemplate.updateUrl = '/setting';
    dataTemplate.createUrl = '/setting';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Nastaveni";
    dataTemplate.editable = [Editable.Create, Editable.Edit]
    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default SettingsAdminView;
