import { INavigationMenuItemProps, NavigationMenuNode, INavigationMenuProps } from './NavigationMenuModels';
import "./NavigationMenu.scss";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHatCowboy} from "@fortawesome/free-solid-svg-icons/faHatCowboy";


const NavigationMenuItem = ({ text, link }: INavigationMenuItemProps) => {
    return (
        <li>
            <Link to={{pathname: link}}>{text}</Link>
        </li>
    );
}

const RenderNavigationMenuNode = (navigationMenuNode: NavigationMenuNode) => {

    if(navigationMenuNode.link){
        return (
            <NavigationMenuItem key={navigationMenuNode.link} link={navigationMenuNode.link} text={navigationMenuNode.text} />
        )
    }

    if(navigationMenuNode.navigationMenuNodes === undefined || navigationMenuNode.navigationMenuNodes?.length === 0){
        return (<></>)
    }

    return (
        <li>
            <span>{navigationMenuNode.text}</span>
            <ul className='submenu'>
                {navigationMenuNode.navigationMenuNodes.map((node: NavigationMenuNode) => {
                    return <NavigationMenuItem key={node.link} link={node.link} text={node.text} />;
                })}
            </ul>
        </li>
    )
}

const NavigationMenu = ({ navigationMenuNodes = [], headerText, headerVersion, previewFunction}: INavigationMenuProps) => {

    let headerContent;

    if (headerText) {
        headerContent = (

            <div className='nav-menu-header-content'>
                <div className={"nav-menu-header-label"}>{headerText}</div>
                <div className={"nav-menu-header-version"}>{headerVersion}</div>
                <span className={"nav-menu-preview"}>
                    {previewFunction &&
                        <button onClick={() => previewFunction()}>P</button>
                    }
                </span>
            </div>
        )
    }

    return (
        <nav className={"nav-menu"}>
            {headerContent}
            <ul className='menu'>
                {
                    navigationMenuNodes.map(navMenuItem => {
                        return RenderNavigationMenuNode(navMenuItem);
                    })
                }
            </ul>
        </nav>
    );
}

export default NavigationMenu;