import { ISideNavigationMenuItemProps, SideNavigationMenuNode, ISideNavigationMenuProps } from './SideNavigationMenuModels';
import "./SideNavigationMenu.scss";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHatCowboy} from "@fortawesome/free-solid-svg-icons/faHatCowboy";
import {useState} from "react";


const SideNavigationMenuItem = ({ text, link, icon, collapsed }: ISideNavigationMenuItemProps) => {

    return (
        <li>
            <Link to={{pathname: link}}>{icon && <FontAwesomeIcon icon={icon}/>} {!collapsed && text}</Link>
        </li>
    );
}

const RenderSideNavigationMenuNode = (navigationMenuNode: SideNavigationMenuNode, collapsed: boolean) => {

    if(navigationMenuNode.link){
        return (
            <SideNavigationMenuItem collapsed={collapsed} key={navigationMenuNode.link} link={navigationMenuNode.link} text={navigationMenuNode.text} icon={navigationMenuNode.icon} />
        )
    }

    if(navigationMenuNode.navigationMenuNodes === undefined || navigationMenuNode.navigationMenuNodes?.length === 0){
        return (<></>)
    }

    return (
        <li>
            <span>{navigationMenuNode.text}</span>
            <ul className='side-submenu'>
                {navigationMenuNode.navigationMenuNodes.map((node: SideNavigationMenuNode) => {
                    return <SideNavigationMenuItem collapsed={collapsed} key={node.link} link={node.link} text={node.text} icon={node.icon} />;
                })}
            </ul>
        </li>
    )
}

const SideNavigationMenu = ({ navigationMenuNodes = [], headerText, headerVersion, collapsedFunction, collapsedMenu }: ISideNavigationMenuProps) => {
    let headerContent;

    console.log(navigationMenuNodes)

    const getMenuClass = () => {
        if(collapsedMenu){
            return "side-nav-menu collapsed";
        }

        return "side-nav-menu";
    }


    const handleCollapse = () => {
        collapsedFunction(!collapsedMenu);
    }

    if (headerText) {
        headerContent = (

            <div className={"side-nav-menu-header-content"}>
                <div className={"side-nav-menu-header-label"}><FontAwesomeIcon icon={faHatCowboy} />{!collapsedMenu && headerText}</div>
                <div className={"side-nav-menu-preview"}>

                    <button onClick={() => handleCollapse()}>{">>"}</button>

                </div>
            </div>
        )
    }

    return (
        <nav className={getMenuClass()}>
            {headerContent}
            <ul className='side-menu'>
                {
                    navigationMenuNodes.map(navMenuItem => {
                        return RenderSideNavigationMenuNode(navMenuItem, collapsedMenu);
                    })
                }
            </ul>
        </nav>
    );
}

export default SideNavigationMenu;