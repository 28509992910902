import React, {useEffect, useState} from "react";
import {Dictionary} from 'express-serve-static-core';
import "./ContentGallery.scss";
import {Upload} from 'antd';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import fetcher, {convertEndpointUrl, getToken} from "../../../tools/Fetcher";
import {HttpRequestHeader} from "antd/lib/upload/interface";


enum ContentType {
    Image = 0,
    Video = 1
}

export class ContentModel {
    contentID: number = 0
    contentType: ContentType = ContentType.Image
    name: string = ""
    path: string = ""
    isActive: boolean = false
    selected: boolean = false

    constructor(contentID: number, contentType: ContentType, name: string, path: string, isActive: boolean){
        this.contentID = contentID;
        this.contentType = contentType;
        this.name = name;
        this.path = path;
        this.isActive = isActive;
        
    }

    public getImagePath(){
        return this.path.replace("images/", "");
    }
}

type ContentGalleryItemProps = {
    contentModel: ContentModel
    selectItem: Function
}

const ContentGalleryItem = ({contentModel, selectItem}: ContentGalleryItemProps) => {


    const getClass = () =>{
        if(contentModel.selected){
            return "image-gallery-item-container selected"
        }
        return "image-gallery-item-container"
    }

    return(
        <div className={getClass()} onClick={() => selectItem(contentModel)}>
            <img alt={contentModel.name} src={contentModel.path}></img>
            <div className="image-cover"></div>
        </div>
    )
}


const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

const Uploader = () => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj as RcFile, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
      };

    const getUploaderHeaders = (): HttpRequestHeader => {
        return {
            "Authorization": `Bearer ${getToken()}`
        };
    }

    return (
        <div className="image-gallery-upload-wrapper">
            <Upload 
                action={convertEndpointUrl("/content/gallery/imageUpload")}
                name="uploadedImage" 
                className="uploader" 
                onChange={handleChange}
                showUploadList={false}
                headers={getUploaderHeaders()}
            >
                <div className="image-gallery-upload">Nahrat Obrazek</div>
            </Upload>
        </div>
    )
}

type ContentGalleryProps = {
    selectContent: Function
}


const ContentGallery = ({selectContent}: ContentGalleryProps) => {
    const [contentList, setContentList] = useState<ContentModel[]>()
    const [selectedItem, setSelectedItem] = useState<ContentModel>();

    const url = "/content/gallery";

    const selectItem = (value: ContentModel) => {
        if(selectedItem){
            selectedItem.selected = false;
        }
        if(selectedItem != value){
            value.selected = true;
            setSelectedItem(value);
        }
        else{
            setSelectedItem(undefined);
        }
    }

    const confirmSelect = (confirm: boolean) => {
        selectContent(selectedItem, confirm);
    }

    const refreshData = () => {
        fetcher(url)
        .then(response => response.json())
        .then(result => {
            var content: ContentModel[] = [];

            result.forEach((contentItem: Dictionary<string>) => {
                content.push(
                    new ContentModel(
                        Number(contentItem["contentID"]),
                        Number(contentItem["contentType"]),
                        contentItem["name"],
                        contentItem["path"],
                        Boolean(contentItem["isActive"])
                    )
                );
            });
            setContentList(content);
        })
    }

    useEffect(() => {
        refreshData();
    }, [])
    


    return(
        <div>
            <h3>Vyberte Obrazek</h3>
            <div className="image-gallery-content">
                
                {contentList?.map((item: ContentModel)=> {
                    return <ContentGalleryItem key={item.path} contentModel={item} selectItem={selectItem}/>
                })}
            </div>
            <Uploader />
            <div className="image-gallery-controls">
                <button className="btn btn-cancel" onClick={() => confirmSelect(false)}>Zrusit</button>
                <button className="btn btn-accept" onClick={() => confirmSelect(true)}>Potvrdit</button>
            </div>
        </div>
    )
}

export default ContentGallery;