import {IBaseAdminProps} from "./BaseAdminModels";
import {Person} from "../../../../common/PersonSelector";
import {Product} from "../models/ProductModels";

export enum OrderTransactionType {
    Reservation,
    Voucher,
    Others,
    ShopItem
}


export interface IOrderTransactionItemProps {
    itemData: any,
    people?: Person[],
    products?: Product[],
    deleteItem: Function
}
export interface IOrderTransactionFieldProps extends IBaseAdminProps {

}