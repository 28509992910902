import {useEffect, useState} from "react";
import "./EshopDashboardComponent.scss";
import fetcher from "../../../../tools/Fetcher";
import {IOrderTransaction} from "../models/OrderModels";


interface IShopItemProps{
    shopItem: IOrderShopItem
}

interface IConsumer {
    firstName: string,
    lastName: string,
    email?: string,
    phone?: string
}


interface IOrderShopItem{
    consumer: IConsumer
    creationDate: Date,
    orderId: number,
    paymentType: number,
    deliveryType: number,
    orderTransactions: IOrderTransaction[]

}

const EshopItem = ({shopItem}: IShopItemProps) => {


    const getTotalPrice = () => {

    }

    const getPaymentType = () => {
        if(shopItem.paymentType === 1){
            return "Hotove"
        }
        if(shopItem.paymentType === 2){
            return "Kartou"
        }
        return "Bankovni prevod"
    }

    const getDeliveryType = () => {
        if(shopItem.deliveryType === 1){
            return "Osobne"
        }
        return "Emailem"
    }


    console.log(shopItem)
    return (
        <div className={"eshop-dashboard-component-item"}>
            <div className={"eshop-consumer eshop-container"}>
                <span className={"eshop-container-item"}>{shopItem.consumer.firstName}</span>
                <span className={"eshop-container-item"}>{shopItem.consumer.lastName}</span>
                <span className={"eshop-container-item"}>{shopItem.consumer.email}</span>
            </div>
            <div className={"eshop-order eshop-container"}>
                <span className={"eshop-container-item"}><b>Id: </b>{shopItem.orderId}</span>
                <span className={"eshop-container-item"}><b>Platba: </b>{getPaymentType()}</span>
                <span className={"eshop-container-item"}><b>Doruceni: </b>{getDeliveryType()}</span>
                <span className={"eshop-container-item"}><b>Datum: </b>{new Date(shopItem.creationDate).toLocaleDateString()}</span>
            </div>
            <div className={"eshop-shop-list eshop-container"}>
                {shopItem.orderTransactions && shopItem.orderTransactions.length > 0
                    ? shopItem.orderTransactions.map(item => {
                        return <div className={"eshop-shop-list-item"}>
                            <span className={"eshop-container-item"}><b>Pocet: </b>{item.shopItemCount}</span>
                            <span className={"eshop-container-item"}><b>Nazev: </b>{item.shopItem?.name}</span>
                            <span className={"eshop-container-item"}><b>Cena: </b>{item.shopItem?.price}</span>

                        </div>
                })
                :
                    <></>
                }
            </div>
        </div>
    )
}

const EshopDashboardComponent = () => {
    const [shopItems, setShopItems] = useState<IOrderShopItem[]>([]);

    useEffect(() => {
        const today = new Date().toISOString()
        fetcher(`/order/eshop`)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setShopItems(result);
            })
    }, []);

    return (
        <div className="eshop-dashboard-component-content">
            <h3>Eshop</h3>
            {shopItems && shopItems.length > 0 ? shopItems.map(item => {
                return <EshopItem shopItem={item} />
            })
            :
            <></>
            }
        </div>
    )
}

export default EshopDashboardComponent;