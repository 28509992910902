import "./VoucherDashboardComponent.scss";
import Input from "antd/es/input/Input";
import {useState} from "react";
import fetcher from "../../../../tools/Fetcher";
import React from "react";
import {AutoComplete} from "antd";

const VoucherDashboardComponent = () => {
    const [voucherCode, setVoucherCode] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [successMsg, setSuccessMsg] = useState<string>("");
    const [options, setOptions] = useState<any>([]);

    const submit = () => {
        fetcher('/voucher/activate',
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({"voucherCode": voucherCode} )
            })
            .then(response => {
                if (response.status == 200) {
                    setVoucherCode("");
                    setErrorMsg("");
                    setSuccessMsg(`Voucher ${voucherCode} aktivovan`);
                    return;
                }
                if (response.status === 404) {
                    setErrorMsg("Voucher nenalezen");
                    setSuccessMsg("");
                    return;
                }
                setErrorMsg("Chyba");
                setSuccessMsg("");
            })
    }

    const handleAutoCompleteChange = (value: any) => {
        setVoucherCode(value);
        if(value.length > 0){
            fetcher(`/voucher/search/${value.toUpperCase()}`)
                .then(response => response.json())
                .then(result => {
                    const optionsArray: any = []
                    result.forEach((item: any) => {
                        optionsArray.push({value: item})
                    })

                    console.log(optionsArray)

                    setOptions(optionsArray);
                })
        }
        else{
            setOptions([]);
        }
    }


    return (<div className={"voucher-dashboard-component-content"}>
        <h3>Aktivace voucheru</h3>
        <div className={"voucher-dashboard-container"}>
            <AutoComplete
                className={"voucher-dashboard-item"}
                placeholder={"Kod voucheru"}
                onChange={(value: any) => handleAutoCompleteChange(value)}
                value={voucherCode}
                options={options}
            />

            <div className={"voucher-dashboard-item"}>
                <button className={"btn"} onClick={submit} disabled={voucherCode.length === 0}>Aktivovat Voucher</button>
            </div>
        </div>
        <div className={"voucher-dashboard-success"}>{successMsg}</div>
        <div className={"voucher-dashboard-error"}>{errorMsg}</div>
    </div>)
}


export default VoucherDashboardComponent;