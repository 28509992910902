import React from 'react';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    AntdTableColumnNestedObject,
    ColumnType,
    Editable, ICustomAction
} from './common/BaseAdminViewModels';
import {FormComplexFieldType} from "./common/baseAdminComponents/FormComplexFieldModels";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan} from '@fortawesome/free-solid-svg-icons'

interface IReservationItem {
    amount: number,
    name: string,
    product?: any,
    voucher?: any,
    person?: any,
    otherVoucherCode?: string
}

const RenderReservationItems = (reservationItem: IReservationItem) => {

    let personContent = <></>;
    let productContent = <></>;
    let voucherContent = <></>;
    let otherVoucherContent = <></>;
    if(reservationItem.person){
        personContent = <span> ({reservationItem.person.firstname} {reservationItem.person.lastname})</span>
    }

    if(reservationItem.product){
        productContent = <span> {reservationItem.product.name}</span>
    }

    if(reservationItem.voucher){
        voucherContent = <span>Voucher {reservationItem.voucher.voucherCode}</span>
    }

    if(reservationItem.otherVoucherCode){
        voucherContent = <span> O-Voucher {reservationItem.otherVoucherCode}</span>
    }



    return <div>{reservationItem.name}{productContent}{personContent}{voucherContent}{otherVoucherContent}</div>
}

function ReservationAdminView() {

    let personNestedObject = new AntdTableColumnNestedObject();
    personNestedObject.key = "firstname"

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "reservationId",
                key: "reservationId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Cena Celkem",
                dataIndex: "price",
                key: "price",
                columnType: ColumnType.Number,
            }),
            new AntdTableColumn({
                title: "Delka",
                dataIndex: "duration",
                key: "duration",
                columnType: ColumnType.Number,
            }),
            new AntdTableColumn({
                title: "Zacatek",
                dataIndex: "startTime",
                key: "startTime",
                columnType: ColumnType.DateTime,
                componentCustomProps: {"showTime": "true"},
                editable: true,
            }),
            new AntdTableColumn({
                title: "Aktivni",
                dataIndex: "isActive",
                key: "isActive",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
            new AntdTableColumn({
                title: "Zakaznik",
                dataIndex: "consumer",
                key: "consumer",
                columnType: ColumnType.Select,
                selectMapLabel: "email",
                selectMapValue: "consumerId",
                dataSourceUrl: "/consumer",
                editable: true,
                render: (_: any, record: any) => {
                    if(!record.consumer){
                        return <div>Zakaznik nenalezen</div>
                    }
                    const name = `${record.consumer.email} ${record.consumer.firstName} ${record.consumer.lastName}`
                    return <div>{name}</div>
                }
            }),
            new AntdTableColumn({
                title: "Polozky",
                dataIndex: "reservationItems",
                key: "reservationItems",
                editable: true,
                columnType: ColumnType.FormComplexField,
                formComplexFieldType: FormComplexFieldType.ReservationItemField,
                render: (_: any, record: any) => {
                    return <div>
                        {record.reservationItems.map((reservationItem: any) => {
                            return RenderReservationItems(reservationItem)
                        })}
                    </div>
                }
            }),
        ]
    }

    const cancelOrder = (record: any) => {
        alert("Zruseni objednavky")
        console.log(record);
    }

    let customActions: ICustomAction[] = [];
    customActions.push({
        buttonContent: <FontAwesomeIcon icon={faBan} />,
        executeFunction: cancelOrder,
        buttonTitle: "Zrusit rezervaci"
    })

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/reservation';
    dataTemplate.updateUrl = '/reservation';
    dataTemplate.createUrl = '/reservation';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Rezervace";
    dataTemplate.editable = [Editable.Create, Editable.Edit];
    dataTemplate.customActions = customActions;

    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default ReservationAdminView;
