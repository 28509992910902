import React, { useEffect, useState } from "react";
import {Select, Input, InputNumber} from 'antd';
import "./ProductPriceOptions.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faCheck, faBan } from "@fortawesome/free-solid-svg-icons";

import {IProductPriceOption, IProductPriceOptionItemProps, IProductPriceOptionProps} from "./ProductPriceOptionsModels";



const ProductPriceOptionItem = ({deleteItem, columnKey, data, productPriceOption, updateData}: IProductPriceOptionItemProps) => {

    const handleUpdatePrice = (value: number | null) => {
        if(!value){
            return;
        }
        updateData(columnKey, data[columnKey].map((option: IProductPriceOption) => {
                if(productPriceOption.productPriceOptionId == option.productPriceOptionId){
                   option.price = value;
                }
                return option;
            }
        ))
    }

    const handleUpdateDuration = (value: number | null) => {
        if(!value){
            return;
        }
        updateData(columnKey, data[columnKey].map((option: IProductPriceOption) => {
                if(productPriceOption.productPriceOptionId == option.productPriceOptionId){
                    option.duration = value;
                }
                return option;
            }
        ))
    }

    return (
        <div className={"product-price-option-field-item"}>
            <div className={"product-price-option-item-content"}>
                <div>
                    <div>
                        <label>Cena</label><InputNumber value={productPriceOption.price} onChange={(val) => handleUpdatePrice(val)}/>
                    </div>
                    <div>
                        <label>Delka</label><InputNumber value={productPriceOption.duration} onChange={(val) => handleUpdateDuration(val)}/>
                    </div>
                </div>
            </div>
            <div className={"product-price-option-item-controls"}>
                <button className='btn' onClick={() => deleteItem(productPriceOption)}><FontAwesomeIcon icon={faTrash} /></button>
            </div>
        </div>
    )
}

export const ProductPriceOptions = ({ data, columnTemplate, updateData }: IProductPriceOptionProps) => {

    console.log(data[columnTemplate.key])

    const handleAddNewItem = () => {
        let smallestId = 0;
        console.log(data[columnTemplate.key].length)

        if(data[columnTemplate.key].length > 0) {
            smallestId = data[columnTemplate.key].reduce((previous: IProductPriceOption, current: IProductPriceOption) => {
                return previous.productPriceOptionId < current.productPriceOptionId ? previous : current
            }).productPriceOptionId - 1;
        }

        if(smallestId > 0){
            smallestId = 0;
        }

        const productPriceOption: IProductPriceOption = {
            productPriceOptionId: smallestId,
            duration: 0,
            price: 0
        }

        updateData(columnTemplate.key, [...data[columnTemplate.key], productPriceOption]);
    }

    const handleDeleteItem = (item: IProductPriceOption) => {
        console.log("Delete Item");
        console.log(item);
        updateData(columnTemplate.key, data[columnTemplate.key]
            .filter((option: IProductPriceOption) => option.productPriceOptionId != item.productPriceOptionId)
        );
    }

    const getItems = () =>{
        if(!(columnTemplate.key in data)){
            return "";
        }
        return data[columnTemplate.key].map((item: any) => {
            return <ProductPriceOptionItem
                data={data}
                columnKey={columnTemplate.key}
                deleteItem={handleDeleteItem}
                updateData={updateData}
                key={item.orderTransactionID}
                productPriceOption={item}
            />
        })
    }
    
    return (
        <div className={"product-price-option-field-container"}>
            <div className={"product-price-option-field-container-controls"}>
                <button className='btn' onClick={() => handleAddNewItem()}><FontAwesomeIcon icon={faPlus}/> Pridat</button>
            </div>
            <div className={"product-price-option-field-container-item-wrapper"}>
                {getItems()}
            </div>
        </div>
    )
}
