import "./AdvancedCheckbox.scss";
import React, {ChangeEvent} from "react";


interface IAdvancedCheckboxProps{
    label: string,
    value: boolean,
    updateFunction: Function,
    disabled?: boolean
}


export const AdvancedCheckbox = ({label, value, updateFunction, disabled = false}: IAdvancedCheckboxProps) => {

    const handleCheckboxUpdate = (e: ChangeEvent<HTMLInputElement>) =>{
        const {checked} = e.target;
        console.log(checked);
        updateFunction(checked);
    }



    return (
        <div className={"advanced-checkbox-container"}>
            <div className={"advanced-checkbox-value"}>
               <input id={label} type={"checkbox"} checked={value} disabled={disabled} onChange={handleCheckboxUpdate}/>
               <label htmlFor={label}>{label}</label>
            </div>
        </div>
    )
}