import React, { ReactNode } from 'react';
import { GetText } from '../../tools/GetText';
import { Table, Modal } from 'antd';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    AntdTableColumnNestedObject,
    ColumnType,
    Editable, ICustomAction, SelectType
} from './common/BaseAdminViewModels';
import {Person} from "../../common/PersonSelector";
import {FormComplexFieldType} from "./common/baseAdminComponents/FormComplexFieldModels";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan} from '@fortawesome/free-solid-svg-icons'

interface IOrderTransaction {
    amount: number,
    name: string,
    product?: any,
    voucher?: any,
    person?: any
}

const RenderOrderTransaction = (orderTransaction: IOrderTransaction) => {

    let additionalContent = <></>;

    if(orderTransaction.person){
        additionalContent = <span> ({orderTransaction.person.firstname} {orderTransaction.person.lastname})</span>
    }

    return <div>{orderTransaction.name}{additionalContent} | {orderTransaction.amount}CZK</div>
}

const OrderStatusDataSource: any = [
    {
        "value": 1,
        "label": "Navrh"
    },
    {
        "value": 2,
        "label": "Rezervovana"
    },
    {
        "value": 3,
        "label": "Zrusena"
    },
    {
        "value": 4,
        "label": "Zaplacena"
    },
    {
        "value": 5,
        "label": "Nevalidni"
    }
]

function OrderAdminView() {

    let personNestedObject = new AntdTableColumnNestedObject();
    personNestedObject.key = "firstname"

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "orderId",
                key: "orderId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Cena Celkem",
                dataIndex: "totalAmount",
                key: "totalAmount",
                columnType: ColumnType.Number,
                componentCustomProps: {"addonAfter": "CZK"},
                render: (_: any, record: any) => {
                    return <div>
                        {record.totalAmount - record.discount + record.priceAdjustment}
                    </div>
                }
            }),
            new AntdTableColumn({
                title: "Uprava Ceny",
                dataIndex: "priceAdjustment",
                key: "priceAdjustment",
                columnType: ColumnType.Number,
                editable: true,
                componentCustomProps: {"addonAfter": "CZK"}
            }),
            new AntdTableColumn({
                title: "Delka",
                dataIndex: "duration",
                key: "duration",
                columnType: ColumnType.Number,
                componentCustomProps: {"addonAfter": "min"}
            }),
            new AntdTableColumn({
                title: "Stav",
                dataIndex: "orderStatus",
                key: "orderStatus",
                columnType: ColumnType.Select,
                selectType: SelectType.Value,
                editable: true,
                staticDataSource: OrderStatusDataSource,
                render: (_: any, record: any) => {
                    if(record.orderStatus <= 0){
                        return <></>
                    }
                    return <div>{OrderStatusDataSource.find((x: any) => x.value === record.orderStatus).label}</div>
                },
            }),
            new AntdTableColumn({
                title: "Zakaznik",
                dataIndex: "consumer",
                key: "consumer",
                columnType: ColumnType.Select,
                selectMapLabel: "email",
                selectMapValue: "consumerId",
                dataSourceUrl: "/consumer",
                editable: true,
                render: (_: any, record: any) => {
                    if(!record.consumer){
                        return <div>Zakaznik nenalezen</div>
                    }
                    const name = `${record.consumer.email} ${record.consumer.firstName} ${record.consumer.lastName}`
                    return <div>{name}</div>
                }
            }),
            new AntdTableColumn({
                title: "Polozky",
                dataIndex: "orderTransactions",
                key: "orderTransactions",
                editable: true,
                columnType: ColumnType.FormComplexField,
                formComplexFieldType: FormComplexFieldType.OrderTransactionField,
                render: (_: any, record: any) => {
                    return <div>

                    </div>
                }
            }),
        ]
    }

    const cancelOrder = (record: any) => {
        alert("Zruseni objednavky")
        console.log(record);
    }

    let customActions: ICustomAction[] = [];
    customActions.push({
        buttonContent: <FontAwesomeIcon icon={faBan} />,
        executeFunction: cancelOrder,
        buttonTitle: "Zrusit objednavku"
    })

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/order';
    dataTemplate.updateUrl = '/order';
    dataTemplate.createUrl = '/order';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Objednavky";
    dataTemplate.editable = [Editable.Edit];
    dataTemplate.customActions = customActions;

    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default OrderAdminView;
