import React from 'react';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    ColumnType,
    CustomControlItem,
    Editable,
    SelectType
} from './common/BaseAdminViewModels';


const UnitStaticDataSource: any = [
    {
        "value": 1,
        "label": "CZK - Koruny"
    },
    {
        "value": 2,
        "label": "% - Procenta"
    },
    {
        "value": 3,
        "label": "Pocet pouziti"
    }
]

function VoucherTypeAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "voucherTypeId",
                key: "voucherTypeId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Nazev",
                dataIndex: "name",
                key: "name",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Jednotky",
                dataIndex: "unitOfValue",
                key: "unitOfValue",
                columnType: ColumnType.Select,
                selectType: SelectType.Value,
                editable: true,
                staticDataSource: UnitStaticDataSource,
                render: (_: any, record: any) => {
                    console.log(record);
                    if(record.unitOfValue <= 0){
                        return <></>
                    }
                    return <div>{UnitStaticDataSource.find((x: any) => x.value === record.unitOfValue).label}</div>
                }
            }),
            new AntdTableColumn({
                title: "Hodnota",
                dataIndex: "value",
                key: "value",
                columnType: ColumnType.Number,
                editable: true,
                infoHelper: "Hodnota je zavisla na vybrane jednotce bud v kc nebo %, nevztahuje se na pocet pouziti",
            }),
            new AntdTableColumn({
                title: "Pocet pouziti",
                dataIndex: "usageCount",
                key: "usageCount",
                columnType: ColumnType.Number,
                editable: true,
                infoHelper: "Omezuje dany voucher na urcite mnoztvi pouziti, pro neomezeny pocet pouziti - 0",
            }),
            new AntdTableColumn({
                title: "Produkty",
                dataIndex: "products",
                key: "products",
                columnType: ColumnType.Select,
                editable: true,
                selectMapLabel: "name",
                selectMapValue: "productId",
                dataSourceUrl: '/product',
                selectMultiple: true,
                infoHelper: "Voucher se vztahuje jen tyto produkty, prazdne pole znamena vsechny produkty",
                render: (_: any, record: any) => {
                    console.log(record);
                    if(!record.products){
                        return <></>
                    }
                    return <div>{record.products.length}</div>
                }
            }),
            new AntdTableColumn({
                title: "Delka masaze",
                dataIndex: "productDuration",
                key: "productDuration",
                columnType: ColumnType.Number,
                editable: true,
                infoHelper: "Voucher se vztahuje jen tuto delku masaze (0 = jakakoliv delka)",
            }),
        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/voucher/type';
    dataTemplate.updateUrl = '/voucher/type';
    dataTemplate.createUrl = '/voucher/type';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Typy Voucheru";
    dataTemplate.editable = [Editable.Create, Editable.Edit]
    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default VoucherTypeAdminView;
