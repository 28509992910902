import React from 'react';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    ColumnType,
    Editable,
    SelectType
} from './common/BaseAdminViewModels';
import {FormComplexFieldType} from "./common/baseAdminComponents/FormComplexFieldModels";
import {IProductPriceOption} from "./common/baseAdminComponents/ProductPriceOptionsModels";


const ProductTypesDataSource: any = [
    {
        "value": 1,
        "label": "Normalni"
    },
    {
        "value": 2,
        "label": "Parova"
    },
    {
        "value": 3,
        "label": "Nerezervovatelna"
    }
]

function TestAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "productId",
                key: "productId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Nazev",
                dataIndex: "name",
                key: "name",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Cena",
                dataIndex: "price",
                key: "price",
                editable: true,
                componentCustomProps: {"addonAfter": "CZK"}
            }),
            new AntdTableColumn({
                title: "Delka",
                dataIndex: "duration",
                key: "duration",
                editable: true,
                columnType: ColumnType.Number,
                componentCustomProps: {"addonAfter": "min"}
            }),
            new AntdTableColumn({
                title: "Cenove variace",
                dataIndex: "productPriceOptions",
                key: "productPriceOptions",
                editable: true,
                columnType: ColumnType.FormComplexField,
                formComplexFieldType: FormComplexFieldType.PersonPriceOptions,
                render: (_: any, record: any) => {
                    if(record.productPriceOptions.length == 0){
                        return <span>-</span>
                    }
                    return <div>
                        {record.productPriceOptions.map((productPriceOption: IProductPriceOption) => {
                            return <div>{productPriceOption.price}CZK|{productPriceOption.duration}min</div>
                        })}
                    </div>
                }
            }),
            new AntdTableColumn({
                title: "Obrazek",
                dataIndex: "imagePath",
                key: "imagePath",
                editable: true,
                columnType: ColumnType.Image
            }),
            new AntdTableColumn({
                title: "Aktivni",
                dataIndex: "isActive",
                key: "isActive",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
            new AntdTableColumn({
                title: "Hlavni Produkt",
                dataIndex: "mainProduct",
                key: "mainProduct",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
            new AntdTableColumn({
                title: "Poradi",
                dataIndex: "displayOrder",
                key: "displayOrder",
                editable: true,
                columnType: ColumnType.Number
            }),
            new AntdTableColumn({
                title: "Typ",
                dataIndex: "productType",
                key: "productType",
                columnType: ColumnType.Select,
                selectType: SelectType.Value,
                editable: true,
                staticDataSource: ProductTypesDataSource,
                render: (_: any, record: any) => {
                    if(record.productType <= 0){
                        return <></>
                    }
                    return <div>{ProductTypesDataSource.find((x: any) => x.value === record.productType).label}</div>
                }
            }),
            new AntdTableColumn({
                title: "Popis",
                dataIndex: "shortDescription",
                key: "shortDescription",
                editable: true,
                columnType: ColumnType.Html,
                tableHidden: true
            }),
            new AntdTableColumn({
                title: "Dlouhy Popis",
                dataIndex: "longDescription",
                key: "longDescription",
                editable: true,
                columnType: ColumnType.Html,
                componentCustomProps: {"rows": "10"},
                tableHidden: true
            }),
            new AntdTableColumn({
                title: "Vlastni Popis",
                dataIndex: "customValue",
                key: "customValue",
                infoHelper: "Popis se zobrazi misto ceny a casu",
                editable: true,
                columnType: ColumnType.Text,
                tableHidden: true
            }),
            new AntdTableColumn({
                title: "Specialni nabidka",
                dataIndex: "specialOffer",
                key: "specialOffer",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/product';
    dataTemplate.updateUrl = '/product';
    dataTemplate.createUrl = '/product';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Masaze";
    dataTemplate.editable = [Editable.Create, Editable.Edit, Editable.Delete]

    return (
        <BaseAdminView testString={"preview"} template={dataTemplate}/>
    );
}

export default TestAdminView;
