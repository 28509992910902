import React from 'react';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    ColumnType,
    CustomControlItem,
    Editable,
    StandardAction
} from './common/BaseAdminViewModels';
import VoucherGenerator from "./common/baseAdminComponents/VoucherGenerator";


const GenerateVouchers = () => {

}

function VoucherAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Kod",
                dataIndex: "voucherCode",
                key: "voucherCode",
                editable: true,
                isPrimaryKey: true
            }),
            new AntdTableColumn({
                title: "Aktivni Od",
                dataIndex: "assigmentDate",
                key: "assigmentDate",
                columnType: ColumnType.DateTime,
                editable: true,
            }),
            new AntdTableColumn({
                title: "Zbyva",
                dataIndex: "remainingValue",
                key: "remainingValue",
                columnType: ColumnType.Number,
                editable: true,
                render: (_: any, record: any) => {
                    console.log(record);
                    if(record.voucherType.unitOfValue == 1){
                        return <div>{record.remainingValue} CZK</div>
                    }
                    if(record.voucherType.usageCount == 0){
                        return <div>Neomezeno</div>
                    }
                    return <div>{record.remainingValue} pouziti</div>
                }
            }),
            new AntdTableColumn({
                title: "Expirace",
                dataIndex: "expirationDate",
                key: "expirationDate",
                columnType: ColumnType.DateTime,
                editable: true,
            }),
            new AntdTableColumn({
                title: "Typ",
                dataIndex: "voucherType",
                key: "voucherType",
                columnType: ColumnType.Select,
                selectMapLabel: "name",
                selectMapValue: "voucherTypeId",
                dataSourceUrl: "/voucher/type",
                editable: true,
            }),

        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/voucher';
    dataTemplate.updateUrl = '/voucher';
    dataTemplate.createUrl = '/voucher';
    dataTemplate.deleteUrl = '/voucher';
    dataTemplate.table = tableConfiguration;
    dataTemplate.searchable = true;
    dataTemplate.headerText = "Vouchery";
    dataTemplate.editable = [Editable.Create, Editable.Edit, Editable.Delete]
    dataTemplate.customControls = {
        overrideDefault: false,
        controlItems: [{
            controlType: CustomControlItem.Button,
            execute: GenerateVouchers,
            label: "Generovat",
            content: <VoucherGenerator />,
            actionAfterExecution: StandardAction.RefreshData
        }]
    }
    dataTemplate.conditionFilters = [
        {name: "Neprirazene", value: "notAssigned"},
        {name: "Aktivni", value: "inUse"},
        {name: "Pouzite", value: "used"},
    ]

    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default VoucherAdminView;
