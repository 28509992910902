
const { NODE_ENV } = process.env;

let savedToken: string = "";

const updateOptions = (options: any) => {
    if(!savedToken){
        return options;
    }

    const update = { ...options };
    update.headers = {
        ...update.headers,
        Authorization: `Bearer ${savedToken}`,
    };
    return update;
}

export const convertEndpointUrl = (endpoint: string) => {
    if (NODE_ENV == 'development'){
        endpoint = `https://localhost:7267${endpoint}`
    }
    return endpoint;
}

const handleResponse = (response: any) => {
    if (response.status === 403 || response.status === 401) {
        console.log("Incorrect user. Login please", 2);
        //inMemoryJWTManager.eraseToken();
        window.location.href = '/';
    }
    return response;
};

export const getToken = (): string =>{
    return savedToken;
}

export const fetcherSaveToken = (token: string) => {
    savedToken = token;
}

const fetcher = async (endpoint: string, options: any = undefined, ignoreLoginRedirect: boolean = false) => {
    if (NODE_ENV == 'development'){
        endpoint = `https://localhost:7267${endpoint}`
    }
    const response = await fetch(endpoint, updateOptions(options));
    if(ignoreLoginRedirect){
        return response;
    }
    return handleResponse(response);
}

export default fetcher;
