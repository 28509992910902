import {AdminTemplate, AntdTable, AntdTableColumn, Editable} from "./common/BaseAdminViewModels";
import BaseAdminView from "./common/BaseAdminView";
import React from "react";

const ConsumerAdminView = () =>{
    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "consumerId",
                key: "consumerId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Jmeno",
                dataIndex: "firstName",
                key: "firstName",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Prijmeni",
                dataIndex: "lastName",
                key: "lastName",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Telefon",
                dataIndex: "phone",
                key: "phone",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Email",
                dataIndex: "email",
                key: "email",
                editable: true,
            })
        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/consumer';
    dataTemplate.updateUrl = '/consumer';
    dataTemplate.createUrl = '/consumer';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Zakaznici";
    dataTemplate.editable = [Editable.Create, Editable.Edit]

    return (
        <BaseAdminView template={dataTemplate}/>
    );

}

export default ConsumerAdminView;