import {useEffect, useState} from "react";
import "./ReservationDashboardComponent.scss";
import fetcher from "../../../../tools/Fetcher";
import {IReservation} from "../models/ReservationModels";
import {IReservationItem} from "../baseAdminComponents/ReservationItemFieldModels";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faDollarSign} from '@fortawesome/free-solid-svg-icons'
import AdvancedLabel from "./common/AdvancedLabel";
import {IOrder, OrderStatus, TranslateOrderStatus} from "../models/OrderModels";


interface IReservationItemProps{
    orderItem: IOrder
}


const ReservationItem = ({orderItem}: IReservationItemProps) => {




    console.log(orderItem)

    if(!orderItem.orderTransactions || orderItem.orderTransactions?.length === 0){
        return <></>
    }

    const currentReservation = orderItem.orderTransactions[0].reservation;
    if(!currentReservation || !currentReservation.reservationItems){
        return <></>
    }

    const getTotalPrice = () => {
        if(currentReservation.price && currentReservation.discount)
            return currentReservation.price - currentReservation.discount
        return currentReservation.price
    }

    const isCancelled = orderItem.orderStatus === OrderStatus.Canceled

    return (
        <div className={"reservation-dashboard-component-item " + (isCancelled ? "canceled" : "")}>
            {isCancelled && <div className={"cancel-overlay"}>ZRUSENO</div> }

            <div className={"reservation-dashboard-component-info"}>
                <AdvancedLabel label={"Stav"} value={TranslateOrderStatus(orderItem.orderStatus)}/>
                <AdvancedLabel label={"Cena"} value={getTotalPrice()?.toString()} suffix={" KC"} />
                <AdvancedLabel label={"Start"} value={new Date(currentReservation.startTime).toLocaleString()}/>
                <AdvancedLabel label={"Delka"} value={currentReservation.duration.toString()} suffix={" min"}/>
                <AdvancedLabel label={"Zakaznik"} value={`${currentReservation.consumer["firstName"]} ${currentReservation.consumer["lastName"]}`}/>
                <AdvancedLabel label={"Email"} value={currentReservation.consumer["email"]}/>
                <AdvancedLabel label={"Telefon"} value={currentReservation.consumer["phone"]}/>
            </div>

            {currentReservation.reservationItems?.map((item: IReservationItem) => {
                return <div className={"reservation-dashboard-component-info"}>
                    <AdvancedLabel label={"Produkt"} value={item.product?.name}/>
                    <AdvancedLabel label={"Maser"} value={`${item.person?.firstname} ${item.person?.lastname}`}/>
                    {item.otherVoucherCode && <AdvancedLabel label={"O-Voucher"} value={item.otherVoucherCode} />}
                </div>

            })}

        </div>
    )
}

const ReservationDashboardComponent = () => {
    const [orderItems, setOrderItems] = useState<IOrder[]>([]);

    useEffect(() => {
        const today = new Date().toISOString()
        fetcher(`/reservation/day/${today}`)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setOrderItems(result);
            })
    }, []);

    return (
        <div className="reservation-dashboard-component-content">
            <h3>Rezervace Dnes</h3>
            {orderItems ? orderItems.map(item => {
                return <ReservationItem orderItem={item} />
            })
            :
            <></>
            }
        </div>
    )
}

export default ReservationDashboardComponent;