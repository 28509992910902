import React, {ReactNode, useState} from 'react';
import "./ContentAdminView.scss";
import { ContentEditor, ContentNewsEditor } from "./common/ContentEditor";
import {ContentModalEditor} from "./common/ContentModalEditor";


function ContentAdminView() {

    return (
        <div className='admin-wrapper content-view-wrapper'>
            <h2> Obsah </h2>
            <section>
                <ContentEditor />
            </section>
            <div className={"section-splitter"}><div className={"section-splitter-bg"}></div></div>
            <section>
                <ContentNewsEditor />
            </section>
            <div className={"section-splitter"}><div className={"section-splitter-bg"}></div></div>
            <section>
                <ContentModalEditor />
            </section>
        </div>
    );
}

export default ContentAdminView;
