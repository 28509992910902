import React, { ReactNode } from 'react';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    ColumnType,
    Editable,
    SelectType
} from './common/BaseAdminViewModels';


const PhotoGallerySectionDataSource: any = [
    {
        "value": 1,
        "label": "Prostory"
    },
    {
        "value": 2,
        "label": "Spoluprace"
    }
]


function PhotoGalleryAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "photoGalleryId",
                key: "photoGalleryId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Nazev",
                dataIndex: "name",
                key: "name",

                editable: true,
            }),
            new AntdTableColumn({
                title: "Poradi",
                dataIndex: "order",
                key: "order",
                editable: true,
                columnType: ColumnType.Number
            }),
            new AntdTableColumn({
                title: "Hodnota",
                dataIndex: "value",
                key: "value",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Odkaz",
                dataIndex: "url",
                key: "url",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Sekce",
                dataIndex: "photoGallerySection",
                key: "photoGallerySection",
                columnType: ColumnType.Select,
                selectType: SelectType.Value,
                editable: true,
                staticDataSource: PhotoGallerySectionDataSource,
                render: (_: any, record: any) => {
                    console.log(record);
                    if(record.photoGallerySection <= 0){
                        return <></>
                    }
                    return <div>{PhotoGallerySectionDataSource.find((x: any) => x.value === record.photoGallerySection).label}</div>
                }
            }),
            new AntdTableColumn({
                title: "Obrazek",
                dataIndex: "imagePath",
                key: "imagePath",
                editable: true,
                columnType: ColumnType.Image
            }),
            new AntdTableColumn({
                title: "Alternativni Obrazek",
                dataIndex: "imagePathAlternate",
                key: "imagePathAlternate",
                editable: true,
                columnType: ColumnType.Image
            }),
            new AntdTableColumn({
                title: "Aktivni",
                dataIndex: "isActive",
                key: "isActive",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/content/photo-gallery';
    dataTemplate.updateUrl = '/content/photo-gallery';
    dataTemplate.createUrl = '/content/photo-gallery';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Foto galerie";
    dataTemplate.editable = [Editable.Create, Editable.Edit]
    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default PhotoGalleryAdminView;
