import Admin from "./components/Admin";
import { NotFound } from "./components/NotFound";

const AppRoutes = [
  {
    element: <Admin/>,
    path: '/'
  },
  {
    element: <Admin target="products"/>,
    path: '/products'
  },
  {
    element: <Admin target="timeslots"/>,
    path: '/timeslots'
  },
  {
    element: <Admin target="people"/>,
    path: '/people'
  },
  {
    element: <Admin target="orders"/>,
    path: '/orders'
  },
  {
    element: <Admin target="settings"/>,
    path: '/settings'
  },
  {
    element: <Admin target="peopleRanks"/>,
    path: '/peopleRanks'
  },
  {
    element: <Admin target="consumers"/>,
    path: '/consumers'
  },
  {
    element: <Admin target="vouchers"/>,
    path: '/vouchers'
  },
  {
    element: <Admin target="voucherTypes"/>,
    path: '/voucherTypes'
  },
  {
    element: <Admin target="reservations"/>,
    path: '/reservations'
  },
  {
    element: <Admin target="contents"/>,
    path: '/contents'
  },
  {
    element: <Admin target="dashboard"/>,
    path: '/dashboard'
  },
  {
    element: <Admin target="eshop"/>,
    path: '/eshop'
  },
  {
    element: <Admin target="test"/>,
    path: '/test'
  },
  {
    element: <Admin target="photoGallery"/>,
    path: '/photoGallery'
  },
  {
    element: <Admin target="contentGallery"/>,
    path: '/contentGallery'
  },
  {
    element: <Admin target="workshops"/>,
    path: '/workshops'
  },
  {
    element: <Admin target="users"/>,
    path: '/users'
  },
  {
    element: <NotFound />,
    path: '*'
  }
];

export default AppRoutes;
