import React from 'react';
import BigCalendar from './common/BigCalendar';


function TimeSlotAdminView() {

    
    return (
        <BigCalendar/>
    );
}

export default TimeSlotAdminView;
