import "./DashboardAdminView.scss"
import ReservationDashboardComponent from "./common/dashboardComponents/ReservationDashboardComponent";
import VoucherDashboardComponent from "./common/dashboardComponents/VoucherDashboardComponent";
import EshopDashboardComponent from "./common/dashboardComponents/EshopDashboardComponent";
import WorkshopDashboardComponent from "./common/dashboardComponents/WorkshopDashboardComponent";
import StatisticsDashboardComponent from "./common/dashboardComponents/StatisticsDashboardComponent";


const Dashboard = () => {
    
    return (
        <div className="dashboard-content">
            <h2>Deska</h2>
            <div className={"dashboard-grid"}>
                <div className={"dashboard-grid-column"}>
                    <div className={"dashboard-item"}>
                        <ReservationDashboardComponent />
                    </div>
                    <div className={"dashboard-item"}>
                        <VoucherDashboardComponent />
                    </div>
                    <div className={"dashboard-item"}>
                        <EshopDashboardComponent />
                    </div>
                    <div className={"dashboard-item"}>
                        <WorkshopDashboardComponent />
                    </div>
                </div>
                <div className={"dashboard-grid-column"}>
                    <div className={"dashboard-item"}>
                        <StatisticsDashboardComponent />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;