import React, { ReactNode } from 'react';
import { GetText } from '../../tools/GetText';
import { Table, Modal } from 'antd';
import BaseAdminView from './common/BaseAdminView'
import { AdminTemplate, AntdTable, AntdTableColumn, ColumnType, Editable } from './common/BaseAdminViewModels';


export interface IPersonRank {
    personRankId: number
    name: string
    description?: string
    priceAdjustement: number
}

function PersonRankAdminView () {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "personRankId",
                key: "personRankId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Nazev",
                dataIndex: "name",
                key: "name",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Popis",
                dataIndex: "description",
                key: "description",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Uprava ceny",
                dataIndex: "priceAdjustement",
                key: "priceAdjustement",
                editable: true,
                columnType: ColumnType.Number,
                infoHelper: "Zvyseni/snizeni ceny produktu",
                componentCustomProps: {"addonAfter": "%", "min": -100, "max": 100}
            }),

        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/person/rank';
    dataTemplate.updateUrl = '/person/rank';
    dataTemplate.createUrl = '/person/rank';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Cloveci - Hodnosti";
    dataTemplate.editable = [Editable.Create, Editable.Edit]
    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default PersonRankAdminView;
