import Editor from "./LexicalEditor";

interface IHtmlEditorProps {
    initialHtml: string
    updateData: Function
    dataKey: string

}

function HtmlEditor({initialHtml, updateData, dataKey} : IHtmlEditorProps) {

    const handleChangeContent = (html: string) => {
        updateData(dataKey, html);
    }

    return (
        <Editor initialHtml={initialHtml} handleHtmlOnChange={handleChangeContent}/>
    )
}

export default HtmlEditor;