import {
    IReservationItem,
    IReservationItemFieldProps,
    IReservationItemProps,
} from "./ReservationItemFieldModels";
import './ReservationItemField.scss'
import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {Person} from "../../../../common/PersonSelector";
import {Dictionary} from "express-serve-static-core";
import {TranslateToOptions} from "../CommonMethods";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import fetcher from "../../../../tools/Fetcher";
import {IProductPriceOption} from "./ProductPriceOptionsModels";
import {IVoucher} from "../models/VoucherModels";
import {Product} from "../models/ProductModels";

const ReservationItem = ({itemData, people, products, vouchers, deleteItem, updateData, data, columnTemplateKey}: IReservationItemProps) => {
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedPerson, setSelectedPerson] = useState<Person>();
    const [selectedProductPriceOption, setSelectedProductPriceOption] = useState<IProductPriceOption>();


    useEffect(() => {
        setSelectedProduct(itemData.product);
        setSelectedPerson(itemData.person);

    }, [itemData]);

    console.log("ItemDATA")
    console.log(itemData);

    let content = <></>

    const handleSelectProduct = (productId: number) => {
        if (!products) {
            return;
        }
        const product = products.find(product => product.productId === productId);
        if (!product) {
            return;
        }

        const items = data[columnTemplateKey];
        updateData(columnTemplateKey, items.map((item: any) => {
            if(item.reservationItemId === itemData.reservationItemId){
                item.product = product
            }
            return item;
        }));
        setSelectedProduct(product);
    }

    const handleSelectPerson = (personId: number) => {
        if (!people) {
            return;
        }
        const person = people.find(person => person.personId === personId);
        if (!person) {
            return;
        }

        const items = data[columnTemplateKey];
        updateData(columnTemplateKey, items.map((item: any) => {
            if(item.reservationItemId === itemData.reservationItemId){
                item.person = person
            }
            return item;
        }));
    }

    const handleSelectVoucher = (voucherCode: string) => {
        if (!vouchers) {
            return;
        }
        const voucher = vouchers.find(voucher => voucher.voucherCode === voucherCode);

        const items = data[columnTemplateKey];
        updateData(columnTemplateKey, items.map((item: any) => {
            if(item.reservationItemId === itemData.reservationItemId){
                item.voucher = voucher
            }
            return item;
        }));
    }

    const handleSelectPriceProductOption = (id: number) => {
        if (!selectedProduct || !selectedProduct.productPriceOptions) {
            return;
        }

        const priceOptions = getSelectedProductOptions();
        if(!priceOptions){
            return;
        }
        const priceOption = priceOptions.find(x => x.productPriceOptionId === id);
        if (!priceOption) {
            return;
        }

        console.log("PriceOption");
        console.log(priceOption)
        const items = data[columnTemplateKey];
        updateData(columnTemplateKey, items.map((item: any) => {
            if(item.reservationItemId === itemData.reservationItemId){
                item.productPriceOption = priceOption
            }
            return item;
        }));
        setSelectedProductPriceOption(priceOption);
    }

    const getSelectedProductOptions = (): IProductPriceOption[] | undefined => {
        if(!selectedProduct){
            return undefined;
        }
        const priceOptions: IProductPriceOption[] = [{
            productPriceOptionId: 0,
            price: selectedProduct.price,
            duration: selectedProduct.duration
        }]

        if(selectedProduct.productPriceOptions) {
            return [...priceOptions, ...selectedProduct.productPriceOptions]
        }
        return priceOptions;
    }

    const handleDeleteTransaction = () => {
        deleteItem(itemData);
    }



    content = <div>
        <Select
            options={TranslateToOptions("name", "productId", products)}
            onChange={handleSelectProduct}
            value={itemData.product ? itemData.product.productId : null}/>
        <Select
            options={TranslateToOptions("duration", "productPriceOptionId", getSelectedProductOptions())}
            onChange={handleSelectPriceProductOption}
            value={itemData.productPriceOption ? itemData.productPriceOption.productPriceOptionId : null}/>
        <Select
            options={TranslateToOptions("name", "personId", people)}
            onChange={handleSelectPerson}
            value={itemData.person ? itemData.person.personId : null}/>
        <Select options={TranslateToOptions("voucherCode", "voucherCode", vouchers)}
                onChange={handleSelectVoucher}
                allowClear
                value={itemData.voucher ? itemData.voucher.voucherCode : null}/>
    </div>


    return (
        <div className={"reservation-item-field-item"}>
            <div className={"reservation-item-field-item-content"}>
                {content}
            </div>
            <div className={"reservation-item-field-item-controls"}>
                <button className='btn' onClick={() => handleDeleteTransaction()}><FontAwesomeIcon icon={faTrash}/>
                </button>
            </div>
        </div>
    )
}

const ReservationItemField = ({data, columnTemplate, updateData}: IReservationItemFieldProps) => {
    const [people, setPeople] = useState<Person[]>();
    const [products, setProducts] = useState<Product[]>();
    const [vouchers, setVouchers] = useState<IVoucher[]>();
    const [reservationItems, setReservationItems] = useState<IReservationItem[]>([]);

    const productsGetUrl = "/product";
    const peopleGetUrl = "/person";
    const voucherGetUrl = "/voucher";

    useEffect(() => {
        if (!data || !(columnTemplate.key in data) || !data[columnTemplate.key]) {
            setReservationItems([]);
            return;
        }

        setReservationItems(data[columnTemplate.key].map((item: Dictionary<any>) => {
            const reservationItem: IReservationItem = {
                reservationItemId: item["reservationItemId"],
                amount: item["amount"],
                name: item["name"],
                person: item["person"],
                product: item["product"],
                voucher: item["voucher"],
                productPriceOption: item["productPriceOption"]
            }
            return reservationItem;
        }));

    }, [data]);

    useEffect(() => {
        fetcher(voucherGetUrl)
            .then(response => response.json())
            .then(result => {
               setVouchers(result)
            });

        fetcher(productsGetUrl)
            .then(response => response.json())
            .then(result => {
                setProducts(result);
            });

        fetcher(peopleGetUrl)
            .then(response => response.json())
            .then(result => {
                setPeople(result.map((person: Dictionary<any>): Person => {
                    return {
                        personId: Number(person["personId"]),
                        name: `${person["firstname"]} ${person["lastname"]}`,
                        description: person["shortDescription"],
                        imagePath: person["imagePath"],
                        firstname: person["firstname"],
                        lastname: person["lastname"]
                    }

                }));
            });
    }, []);

    const handleAddNewItem = () => {
        console.log("DATA IS ");
        console.log(data)
        let newReservationItemId = -1;
        if ("reservationItems" in data){
            data.reservationItems.forEach((item: any) => {
                if(item.reservationItemId <= newReservationItemId){
                    newReservationItemId = item.reservationItemId - 1
                }
            })
        }
        let newReservationItem: IReservationItem = {}
        newReservationItem.product = products ? products[0] : undefined;
        newReservationItem.person = people ? people[0] : undefined;
        newReservationItem.voucher = undefined
        newReservationItem.reservationItemId = newReservationItemId;

        reservationItems.push(newReservationItem);
        updateData(columnTemplate.key, reservationItems);
    }

    const handleDeleteItem = (item: IReservationItem) => {
        console.log("Delete Item");
        console.log(item);
        const transactions = reservationItems.filter(transaction => transaction.reservationItemId != item.reservationItemId);
        updateData(columnTemplate.key, transactions);
    }

    const handleUpdateItemData = (reservationItem: any) => {
        const reservationItems = data[columnTemplate.key];

        updateData(columnTemplate.key, reservationItems.map((item: any) => {
            if(reservationItem.reservationItemId === item.reservationItemId){
                item.product = reservationItem.product;
                item.person = reservationItem.person;
                item.productPriceOption = reservationItem.productPriceOption;
            }
            return item;
        }))
    }


    console.log("ReservationItemField Data");
    console.log(data);

    return (
        <div className={"reservation-item-field-container"}>
            <div className={"reservation-item-field-container-controls"}>
                <button className='btn' onClick={() => handleAddNewItem()}><FontAwesomeIcon
                    icon={faPlus}/> Polozka
                </button>
            </div>
            <div className={"reservation-item-field-container-item-wrapper"}>
                {reservationItems.map((item: IReservationItem) => {
                    return <ReservationItem deleteItem={handleDeleteItem}
                                                key={item.reservationItemId}
                                                itemData={item}
                                                data={data}
                                                people={people}
                                                products={products}
                                                vouchers={vouchers}
                                                updateData={updateData}
                                                columnTemplateKey={columnTemplate.key}
                    />
                })}
            </div>
        </div>
    )
}

export default ReservationItemField;