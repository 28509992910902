import { FormComplexFieldType } from "./baseAdminComponents/FormComplexFieldModels";

export class AntdTableColumnNestedObject {
    key: string = "";
    nestedObject?: AntdTableColumnNestedObject;
}

export enum SelectType{
    Object,
    Value,
}

export class AntdTableColumn{
    title: string = ""
    dataIndex: string = ""
    key: string = ""
    hiddenInput: boolean = false
    isPrimaryKey: boolean = false
    render: any
    editable: boolean = false
    columnType: ColumnType = 1
    componentCustomProps: any = {}
    dataSourceUrl: string = ""
    objectKey: string = ""
    nestedObject?: AntdTableColumnNestedObject;
    selectMapValue: string = "value";
    selectMapLabel: string = "label";
    infoHelper: string = "";
    tableHidden: boolean = false;
    formComplexFieldType: FormComplexFieldType = 0;
    staticDataSource?: any[];
    selectType: SelectType = SelectType.Object;
    selectMultiple: boolean = false;
    postKeyOverride: string = "";
    nestedColumns?: AntdTableColumn[];
    searchable: boolean = false;
    sortable: boolean = false;

    public constructor(init?: Partial<AntdTableColumn>) {
        Object.assign(this, init);
    }
}

export interface ISelectIdentity<Type>{
    (dataType: Type): Type;
}

export class AntdTable {
    columns: AntdTableColumn[] = [];
    edit?: any;
}


export enum Editable {
    Edit = 1,
    Create = 2,
    Delete = 3
}

export enum ColumnType {
    Text = 1,
    Image = 2,
    DropDown = 3,
    Checkbox = 4,
    Number = 5,
    DateTime = 6,
    Select = 7,
    Object = 8,
    ObjectCustom = 9,
    FormComplexField = 10,
    TextArea = 11,
    Html = 12,
    MultiRangeSelector = 13
}

export interface ICustomAction {
    executeFunction: Function,
    buttonContent: any,
    buttonTitle: string
}

export enum CustomControlItem{
    Button
}

export enum StandardAction{
    None,
    RefreshData
}

export interface ICustomControlItem{
    controlType: CustomControlItem;
    execute?: Function;
    content?: JSX.Element;
    opensModal?: boolean
    label?: string
    actionAfterExecution?: StandardAction 
}

export interface ICustomControls{
    overrideDefault?: boolean
    controlItems: ICustomControlItem[];
}

export class AdminTemplate {
    table: AntdTable = new AntdTable();
    getUrl: string = "";
    updateUrl: string = "";
    createUrl: string = "";
    deleteUrl: string = "";
    headerText: string = "Placeholder";
    editable: Editable[] = [];
    customControls?: ICustomControls;
    customActions: ICustomAction[] = [];
    searchable: boolean = false;
    conditionFilters?: ConditionFilter[] = undefined;

    getPrmirayKey(){
        return this.table.columns.find(x => x.isPrimaryKey)?.key
    }
}

export interface ConditionFilter{
    name: string;
    value: string;
}