import {useEffect, useState} from "react";
import {IVoucherType} from "../models/VoucherModels";
import fetcher from "../../../../tools/Fetcher";
import {DatePicker, Form, InputNumber, Select} from "antd";
import locale from 'antd/es/date-picker/locale/cs_CZ'
import {TranslateToOptions} from "../CommonMethods";
import {LoadingBar} from "../LoadingBar";

const VoucherGenerator = () => {
    const [voucherTypes, setVoucherTypes] = useState<IVoucherType[]>([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetcher('/voucher/type')
            .then(response => response.json())
            .then(result => setVoucherTypes(result));
    }, []);

    const submit = () => {
        setLoading(true);
        fetcher('/voucher/generate',
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(form.getFieldsValue())
            })
            .then(response => {
                setLoading(false);
            })

    }

    return (<>
            <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }}>
                <Form.Item label={"Pocet"} name={"count"}>
                    <InputNumber />
                </Form.Item>
                <Form.Item label={"Delka Kodu"} name={"codeLenght"}>
                    <InputNumber />
                </Form.Item>
                <Form.Item label={"Expirate "} name={"expiration"}>
                    <DatePicker locale={locale}/>
                </Form.Item>
                <Form.Item label={"Typ Voucheru"} name={"voucherTypeId"}>
                    <Select options={TranslateToOptions("name", "voucherTypeId", voucherTypes)} />
                </Form.Item>
            </Form>

        {loading ?
            <LoadingBar show={true} />
            :
            <button className={"btn"} onClick={() => submit()}>Generovat</button>
        }

        </>)

}

export default VoucherGenerator;