import React from 'react';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    ColumnType,
    Editable,
    SelectType
} from './common/BaseAdminViewModels';
import {FormComplexFieldType} from "./common/baseAdminComponents/FormComplexFieldModels";
import {IProductPriceOption} from "./common/baseAdminComponents/ProductPriceOptionsModels";


const ProductTypesDataSource: any = [
    {
        "value": 1,
        "label": "Normalni"
    },
    {
        "value": 2,
        "label": "Parova"
    }
    ]

function ShopItemAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "shopItemId",
                key: "shopItemId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Nazev",
                dataIndex: "name",
                key: "name",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Cena",
                dataIndex: "price",
                key: "price",
                editable: true,
                componentCustomProps: {"addonAfter": "CZK"}
            }),
            new AntdTableColumn({
                title: "Obrazek",
                dataIndex: "imagePath",
                key: "imagePath",
                editable: true,
                columnType: ColumnType.Image
            }),
            new AntdTableColumn({
                title: "Aktivni",
                dataIndex: "isActive",
                key: "isActive",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
            new AntdTableColumn({
                title: "Poradi",
                dataIndex: "displayOrder",
                key: "displayOrder",
                editable: true,
                columnType: ColumnType.Number
            }),
            new AntdTableColumn({
                title: "Popis",
                dataIndex: "shortDescription",
                key: "shortDescription",
                editable: true,
                columnType: ColumnType.TextArea
            }),
            new AntdTableColumn({
                title: "Dlouhy Popis",
                dataIndex: "longDescription",
                key: "longDescription",
                editable: true,
                columnType: ColumnType.TextArea,
                componentCustomProps: {"rows": "10"}
            }),
            new AntdTableColumn({
                title: "Typ Voucheru",
                dataIndex: "voucherType",
                key: "voucherType",
                columnType: ColumnType.Select,
                selectMapLabel: "name",
                selectMapValue: "voucherTypeId",
                dataSourceUrl: "/voucher/type",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Specialni nabidka",
                dataIndex: "specialOffer",
                key: "specialOffer",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/shopitem';
    dataTemplate.updateUrl = '/shopitem';
    dataTemplate.createUrl = '/shopitem';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Eshop";
    dataTemplate.editable = [Editable.Create, Editable.Edit]
    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default ShopItemAdminView;
