import {Select} from 'antd';
import {IAdminSelectProps} from './AdminSelectModels';
import {useEffect, useState} from 'react';
import {TranslateToOptions} from '../CommonMethods';
import fetcher from "../../../../tools/Fetcher";
import {SelectType} from "../BaseAdminViewModels";
import {Dictionary} from "express-serve-static-core";

//TODO: Fix this 

export const AdminSelect = ({columnTemplate, data, updateData}: IAdminSelectProps) => {
    const [dynamicData, setDynamicData] = useState<any>([]);

    useEffect(() => {
        if(columnTemplate.staticDataSource){
            setDynamicData(columnTemplate.staticDataSource);
            return;
        }

        fetcher(columnTemplate.dataSourceUrl)
            .then(response => response.json())
            .then(result => {
                setDynamicData(TranslateToOptions(columnTemplate.selectMapLabel, columnTemplate.selectMapValue, result));
            })
    }, [setDynamicData])


    const handleSelect = (value: any) => {
        if(columnTemplate.selectMultiple){
            const products: Dictionary<number>[] = [];
            value.forEach((item: any) => {
                products.push({[columnTemplate.selectMapValue]: item})
            })
            updateData(columnTemplate.key, products);
            return;
        }

        if(columnTemplate.selectType === SelectType.Value){
            updateData(columnTemplate.key, value)
            return;
        }
        updateData(columnTemplate.key, {[columnTemplate.selectMapLabel]: "", [columnTemplate.selectMapValue]: value})
    }

    const selectValue = () => {

        if (!(columnTemplate.key in data && data[columnTemplate.key])){
            if(columnTemplate.selectMultiple){
                return [];
            }
            return null;
        }
        if(columnTemplate.selectType === SelectType.Value){
            return data[columnTemplate.key];
        }

        if(columnTemplate.selectMultiple){
            return TranslateToOptions(columnTemplate.selectMapLabel, columnTemplate.selectMapValue, data[columnTemplate.key]);
        }
        return data[columnTemplate.key][columnTemplate.selectMapValue]
    }

    return (
        <Select 
            options={dynamicData} 
            onChange={handleSelect}
            value={selectValue()}
            placeholder={columnTemplate.title}
            mode={columnTemplate.selectMultiple ? "multiple" : ""}
            {...columnTemplate.componentCustomProps}
        />
    )
}


