import React from "react";
import { FormComplexFieldType, IFormComplexFieldProps } from "./FormComplexFieldModels";
import { PersonProfileSelect } from "./PersonProfileSelect";
import OrderTransactionField from "./OrderTransactionField";
import {ProductPriceOptions} from "./ProductPriceOptions";
import ReservationItemField from "./ReservationItemField";
import CustomerPaidListField from "./CustomerPaidListField";

export const FormComplexField = ({data, columnTemplate, updateData}: IFormComplexFieldProps) => {

    if(columnTemplate.formComplexFieldType === FormComplexFieldType.None){
        return <></>
    }

    if(columnTemplate.formComplexFieldType === FormComplexFieldType.OrderTransactionField){
        return <OrderTransactionField data={data} columnTemplate={columnTemplate} updateData={updateData} />
    }

    if(columnTemplate.formComplexFieldType === FormComplexFieldType.PersonPriceOptions){
        return <ProductPriceOptions data={data} columnTemplate={columnTemplate} updateData={updateData} />
    }

    if(columnTemplate.formComplexFieldType === FormComplexFieldType.ReservationItemField){
        return <ReservationItemField data={data} columnTemplate={columnTemplate} updateData={updateData} />
    }

    if(columnTemplate.formComplexFieldType === FormComplexFieldType.ConsumerPaidListField){
        return <CustomerPaidListField data={data} columnTemplate={columnTemplate} updateData={updateData} />
    }

    return (
        <>
            <PersonProfileSelect data={data} columnTemplate={columnTemplate} updateData={updateData} /> 
        </>)
}