import React, { useEffect, useState } from "react";
import { Select, Input, Form } from 'antd';
import "./PersonProfileSelect.scss";
import { Dictionary } from 'express-serve-static-core';
import { GetObjectValues, TranslateToOptions } from "../CommonMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import { IBaseAdminProps } from "./BaseAdminModels";
import {Person} from "../../../../common/PersonSelector";
import fetcher from "../../../../tools/Fetcher";
import {Product} from "../models/ProductModels";

export interface IPersonProfileSelectProps extends IBaseAdminProps {
}

export class PersonProfile {
    personProfileId: number
    name: string
    products: Product[]
    selectedProductIds: number[]

    public constructor(personProfileId: number, name: string, products: Product[], selectedProductIds: number[]) {
        this.personProfileId = personProfileId;
        this.name = name;
        this.products = products;
        this.selectedProductIds = selectedProductIds;
    }
}

export const PersonProfileSelect = ({ data, columnTemplate, updateData }: IPersonProfileSelectProps) => {
    const [profiles, setProfiles] = useState<PersonProfile[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [profileName, setProfileName] = useState<string>("");
    const [editMode, setEditMode] = useState<boolean>(false);
    const [selectedProfile, setSelectedProfile] = useState<PersonProfile>();
    const [selectedProfileId, setSelectedProfileId] = useState<number>();

    const productsGetUrl = "/product"

    useEffect(() => {
        if (data.length === 0 || !("personProfiles" in data)) {
            const newProfile = new PersonProfile(-1, "Vychozi", [], []);

            updateData(columnTemplate.key, [newProfile]);
            setEditMode(false);
            return;
        }

        console.log(data);

        const sanitizedData = data.personProfiles.map((profile: PersonProfile) => {
            let profileProducts: Product[] = [];

            if(profile.products.length > 0){
                profileProducts = profile.products.map((p: any) => {
                    const newProduct: Product = {
                        name: p["name"],
                        description: p["description"],
                        imagePath: p["imagePath"],
                        price: Number(p["price"]),
                        duration: Number(p["duration"]),
                        productId: Number(p["productId"])
                    }
                    return newProduct;
                })
            }

            
            return new PersonProfile(Number(profile.personProfileId), profile.name, profileProducts, profileProducts.map(product => { return product.productId }))
        })

        setProfiles(sanitizedData);
        if(sanitizedData.length > 0 && !sanitizedData.filter((item: PersonProfile) => item.personProfileId === selectedProfile?.personProfileId)){
            console.log("SetSelectedProfile Initialized");
            setSelectedProfile(sanitizedData[0]);
            setSelectedProfileId(sanitizedData[0].personProfileId);
        }
        
    }, [data])

    useEffect(() => {
        fetcher(productsGetUrl)
        .then(response => response.json())
        .then(result => {
            console.log(result);
            setProducts(result.map((product: Dictionary<string>) => {

                return {
                    name: product["name"],
                    description: product["description"],
                    imagePath: product["imagePath"],
                    price: Number(product["price"]),
                    duration: Number(product["duration"]),
                    productId: Number(product["productId"])
                }

            }));
        });

    }, [])


    const commitEditMode = (success: boolean) => {
        if (success) {

            let smallestId = -1;
            if(profiles.length > 0) {
                // TODO: Change to data[columnTemplate.key]
                const lastProfile = profiles.reduce((previous, current) => {
                    return previous.personProfileId < current.personProfileId ? previous : current
                });
                smallestId = lastProfile.personProfileId;
            }

            if (smallestId > 0) {
                smallestId = -1
            }

            const newProfile = new PersonProfile(smallestId - 1, profileName, [], []);


            updateData(columnTemplate.key, [...data[columnTemplate.key], newProfile]);

            setSelectedProfile(newProfile);
            setSelectedProfileId(newProfile.personProfileId);
        }

        setEditMode(false);
        setProfileName("");
    }

    const handleChangeProfileName = (event: React.FormEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;

        setProfileName(value);
    }

    const handleSelectProfile = (value: number) => {
        setSelectedProfileId(value);
    }

    const handleSelectProduct = (productIds: number[]) => {
        if (!selectedProfileId) {
            console.log("No selected Profile");
            return
        }

        console.warn("Handle Select Product");
        console.log(data[columnTemplate.key]);
        console.log(productIds);
        console.log(products.filter(product => productIds.includes(product.productId)));

        const newProfiles = data[columnTemplate.key].map((profile: any) => {
            if (profile.personProfileId === selectedProfileId) {
                profile.products = products.filter(product => productIds.includes(product.productId));
                profile.selectedProductIds = productIds;
            }
            return profile;
        });

        updateData(columnTemplate.key, newProfiles);
    }

    console.log(selectedProfile);


    const getSelectedProfileProducts = () : number[] => {
        if(data === 'undefined' || !data[columnTemplate.key] || data[columnTemplate.key].length < 1){
            return [];
        }

        const profile = data[columnTemplate.key].find((profile: PersonProfile) => profile.personProfileId === selectedProfileId);
        if(profile){
            return profile.products.map((product: Product) => product.productId)
        }
        return [];
    }

    let content = (
        <>
            <div className="person-profile-select-item">
                <Select options={TranslateToOptions("name", "personProfileId", profiles)} onChange={handleSelectProfile} value={selectedProfileId} />
            </div>
            <div className="person-profile-select-item">
                <Select options={TranslateToOptions("name", "productId", products)} onChange={handleSelectProduct} value={getSelectedProfileProducts()} mode="multiple" />
            </div>
            <div className="person-profile-select-options">
                <button className="btn" onClick={() => setEditMode(true)}><FontAwesomeIcon icon={faPlus} /></button>
                <button className="btn"><FontAwesomeIcon icon={faTrash} /></button>

            </div>
        </>
    )

    if (editMode) {
        content = (<>
            <div className="person-profile-select-item">
                <Input onChange={handleChangeProfileName} value={profileName} />
            </div>
            <div className="person-profile-select-options">
                <button className="btn" onClick={() => commitEditMode(true)}><FontAwesomeIcon icon={faCheck} /></button>
                <button className="btn" onClick={() => commitEditMode(false)}><FontAwesomeIcon icon={faBan} /></button>
            </div>
        </>
        )
    }

    return (
        <div className="person-profile-select-container">
            <div className="test">PersonProfileSelect - TEST</div>
            {content}

        </div>
    )
}
