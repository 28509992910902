import {IReservationItem} from "../baseAdminComponents/ReservationItemFieldModels";
import {Person} from "../../../../common/PersonSelector";
import {IShopItem} from "./ShopItemModel";
import {IReservation} from "./ReservationModels";

export enum OrderStatus{
    Draft = 1,
    Reserved = 2,
    Canceled = 3,
    Paid = 4,
    Invalid = 5
}

export const TranslateOrderStatus = (orderStatus: OrderStatus) => {
    switch (orderStatus) {
        case OrderStatus.Paid:
            return "Zaplaceno"
        case OrderStatus.Canceled:
            return "Zruseno"
        case OrderStatus.Draft:
            return "Navrh"
        case OrderStatus.Invalid:
            return "Nevalidni"
        case OrderStatus.Reserved:
            return "Rezevovano"
        default:
            return "NaN"
    }
}

export interface IOrderTransaction {
    orderTransactionID?: number,
    amount?: number,
    name?: string
    reservation?: IReservation
    voucher?: any
    person?: Person,
    shopItem?: IShopItem
    shopItemCount?: number
}

export interface IOrder{
    orderId: number,
    orderStatus: OrderStatus,
    orderTransactions?: IOrderTransaction[],
    paymentType: number
}