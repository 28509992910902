import React, {useCallback, useEffect, useState} from "react";
import {
    Calendar,
    DateCellWrapperProps,
    dateFnsLocalizer,
    Event as ReactBigCalendarEvent,
    Messages
} from 'react-big-calendar';
import './BigCalendar.scss';
import withDragAndDrop, {withDragAndDropProps} from 'react-big-calendar/lib/addons/dragAndDrop'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import cs from 'date-fns/locale/cs'
import addHours from 'date-fns/addHours'
import startOfHour from 'date-fns/startOfHour'
import {
    CalendarEventMenuProps,
    CalendarMenuFunctionType,
    CustomResource,
    IBigCalendarControlsProps,
    ISelectedEvent,
    ITimeSlotRequest
} from "./BigCalendarModels";
import {Form, Input, Select, DatePicker, Checkbox} from "antd";
import {Person} from "../../../common/PersonSelector";
import {PersonProfile} from "./baseAdminComponents/PersonProfileSelect";
import {Dictionary} from 'express-serve-static-core';
import {TranslateToOptions} from "./CommonMethods";
import {DefaultOptionType} from "antd/es/select";
import fetcher from "../../../tools/Fetcher";
import {Label} from "reactstrap";
import Modal from "antd/es/modal/Modal";
import {TimePicker} from "antd/lib";
import {TimeSlot} from "./models/TimeSlotModel";
import dayjs, {Dayjs} from "dayjs";
import 'dayjs/locale/cs';
import locale from 'antd/es/date-picker/locale/cs_CZ'

const { RangePicker } = DatePicker;
const DnDCalendar = withDragAndDrop(Calendar);

const locales = {
    'cs': cs,
}
const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1)
// The types here are `object`. Strongly consider making them better as removing `locales` caused a fatal error
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})//@ts-ignore

const peopleGetUrl = "/person";

const BigCalendarControls = ({selectedPerson ,selectPerson, selectProfile}: IBigCalendarControlsProps) => {
    const [people, setPeople] = useState<Person[]>();
    const [profiles, setProfiles] = useState<PersonProfile[]>();

    useEffect(() => {
        fetcher(peopleGetUrl)
            .then(response => response.json())
            .then(result => {
                setPeople(result.map((person: Dictionary<any>): Person => {
                    return {
                        personId: Number(person["personId"]),
                        name: `${person["firstname"]} ${person["lastname"]}`,
                        description: person["shortDescription"],
                        imagePath: person["imagePath"],
                        personProfiles: person["personProfiles"].map((profile: Dictionary<string>): PersonProfile => {
                            return {
                                name: profile.name,
                                personProfileId: Number(profile.personProfileId),
                                products: [],
                                selectedProductIds: []
                            }
                        })
                    }

                }));
            });
    }, [setPeople])

    const getPeople = (): DefaultOptionType[] => {
        if (!people) {
            return [];
        }
        return TranslateToOptions("name", "personId", people);
    }

    const getProfiles = (): DefaultOptionType[] => {
        if (!profiles) {
            return [];
        }
        return TranslateToOptions("name", "personProfileId", profiles);
    }

    const handleSelectPerson = (personId: number) => {
        if (personId === 0 || !people || people.length === 0) {
            return;
        }
        var selectedPerson = people.find(person => person.personId === personId);
        if (!selectedPerson) {
            return;
        }
        selectPerson(selectedPerson);
        setProfiles(selectedPerson.personProfiles);
        if (selectedPerson.personProfiles) {
            selectProfile(selectedPerson.personProfiles[0]);
        }
    }

    const handleSelectProfile = (profileID: number) => {
        if (profileID === 0) {
            return;
        }
        selectProfile(profiles?.find(profile => profile.personProfileId == profileID));

    }

    return (
        <div className="big-calendar-controls">
            <Label>Osoba </Label>
            <Select options={getPeople()} onChange={handleSelectPerson}/>
            {selectedPerson &&
                <>
                    <Label>Profil </Label>
                    <Select options={getProfiles()} onChange={handleSelectProfile}/>
                </>
            }
        </div>
    )

}


const CalendarEventMenu = ({selectedEvent, calendarMenuFunction}: CalendarEventMenuProps) => {
    console.log(selectedEvent);
    if (!selectedEvent || Object.keys(selectedEvent).length === 0) {
        return <></>
    }

    const leftPosition = selectedEvent.clientX - 150;
    const topPosition = selectedEvent.clientY - 70;
    const {title, start, end} = selectedEvent.calendarEvent;

    return (
        <div className="big-calendar-popup-menu" style={{left: leftPosition, top: topPosition}}>
            <div className="big-calendar-popup-menu-header">
                {title} ({start?.getHours()}:{start?.getMinutes().toString().padStart(2, "0")} - {end?.getHours()}:{end?.getMinutes().toString().padStart(2, "0")})
            </div>
            <div className="big-calendar-popup-menu-item"
                 onClick={() => calendarMenuFunction(CalendarMenuFunctionType.Delete, selectedEvent.calendarEvent)}>
                Smazat
            </div>
            {/*<div className="big-calendar-popup-menu-item"*/}
            {/*     onClick={() => calendarMenuFunction(CalendarMenuFunctionType.Edit, selectedEvent.calendarEvent)}>*/}
            {/*    Upravit*/}
            {/*</div>*/}
            <div className="big-calendar-popup-menu-item"
                 onClick={() => calendarMenuFunction(CalendarMenuFunctionType.Close, selectedEvent.calendarEvent)}>
                Zavrit
            </div>
        </div>
    )
}

interface EditFormProps{
    editEvent?: TimeSlot
    refreshData: Function
    setEditEvent: Function
}

const EditForm = ({editEvent, refreshData, setEditEvent}: EditFormProps) => {
    const [form] = Form.useForm();
    const [profiles, setProfiles ] = useState<PersonProfile[]>([]);
    const [dynamicData, setDynamicData] = useState<any>([]);

    const updateData = (e: any) => {
        console.log(e);
        console.log(form.getFieldsValue());
        const formData = form.getFieldsValue();
        const startTime: Dayjs = formData["startTime"];
        const endTime: Dayjs = formData["endTime"];
        endTime.day(startTime.day())
        endTime.year(startTime.year())
        endTime.month(startTime.month())

        formData["end"] = startTime;
        formData["start"] = endTime;
        formData["personId"] = editEvent?.person?.personId;
        formData["personProfileId"] = formData.personProfile?.personProfileId;

        console.log(formData);

        fetcher('/timeslot', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                setEditEvent(undefined);
                refreshData();
            });

    }

    useEffect(() => {
        if(!editEvent){
            return;
        }
        fetcher(`/person/profile/person/${editEvent.person?.personId}`)
            .then(response => response.json())
            .then(result => {
                setProfiles(result);
                setDynamicData(TranslateToOptions("name", "personProfileId", result))
            })

        const formData = {
            "startTime": editEvent.startTime ? dayjs(new Date(editEvent.startTime)) : new Date(),
            "endTime": editEvent.endTime ? dayjs(new Date(editEvent.endTime)) : new Date(),
            "personProfile": editEvent.personProfile,
            "applyWeekendBonus": true
        }
        form.setFieldsValue(formData);
    }, [editEvent]);

    return (
        <Modal open={editEvent != undefined} onCancel={() => setEditEvent(undefined)} onOk={updateData}>
            <div className={"big-calendar-modal"}>
                <Form form={form}>
                    <Form.Item key={"personProfile"} name={"personProfile"} label={"Profil"}>
                        <Select />
                    </Form.Item>
                    <Form.Item key={"startTimeDate"} name={"startTime"} label={"Datum"}>
                        <DatePicker allowClear={false} locale={locale} />
                    </Form.Item>
                    <Form.Item key={"startTime"} name={"startTime"} label={"Od"}>
                        <TimePicker allowClear={false} minuteStep={15} format={"HH:mm"} locale={locale} />
                    </Form.Item>
                    <Form.Item key={"endTime"} name={"endTime"} label={"Do"}>
                        <TimePicker allowClear={false} minuteStep={15} format={"HH:mm"} locale={locale} />
                    </Form.Item>
                    <Form.Item key={"applyWeekendBonus"} name={"applyWeekendBonus"} label={"Vikendovy priplatek"}>
                        <Checkbox />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}

const BigCalendar = () => {
    const [events, setEvents] = useState<ReactBigCalendarEvent[]>([]);
    const [orderEvents, setOrderEvents] = useState<ReactBigCalendarEvent[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<Person>();
    const [selectedProfile, setSelectedProfile] = useState<PersonProfile>();
    const [editEvent, setEditEvent] = useState<TimeSlot | undefined>(undefined);


    const refreshData = () => {
        if (!selectedPerson) {
            return;
        }

        fetcher(`/timeslot/person/${selectedPerson.personId}`)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                const eventResult = result.map((item: any) => {
                    const newEvent: ReactBigCalendarEvent = {
                        start: new Date(item.startTime),
                        end: new Date(item.endTime),
                        title: item.personProfile?.name,
                        resource: new CustomResource(item.timeSlotId)
                    }
                    return newEvent;
                });
                setEvents(eventResult)
            });

        fetcher(`/reservation/${selectedPerson.personId}`)
            .then(response => response.json())
            .then(result => {
                console.log("Reservations")
                console.log(result);
                const eventResult = result.map((item: any) => {
                    console.log(item);
                    const startTime = new Date(item.startTime);
                    const endTime = new Date(item.startTime);
                    const title: string = `${item.consumer?.firstName} ${item.consumer?.lastName} (${item.consumer?.email}) - ${item.reservationItems[0]?.product?.name}`
                    const newEvent: ReactBigCalendarEvent = {
                        start: startTime,
                        end: new Date(endTime.setMinutes(endTime.getMinutes() + item.duration)),
                        title: title,
                        resource: new CustomResource(item.orderId)
                    }
                    return newEvent;
                });
                setOrderEvents(eventResult)
            });

    }

    const handleEventCreate = (event: ReactBigCalendarEvent, person: Person, profile: PersonProfile) => {
        const startDate = event.start;
        const endDate = event.end;
        if (!startDate || !endDate) {
            return;
        }

        const timeSlotRequest: ITimeSlotRequest = {
            id: event.resource.id,
            start: startDate,
            end: endDate,
            personId: person.personId,
            personProfileId: profile.personProfileId,
        }

        fetcher("/timeslot", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(timeSlotRequest)
        })
            .then(response => {
                refreshData();
            })
    }

    const handleEventUpdate = (event: ReactBigCalendarEvent) => {
        console.log(event);
        console.log("EVENT UPDATE")


        const startDate = event.start;
        const endDate = event.end;
        if (!startDate || !endDate) {
            return;
        }

        const timeSlotRequest: ITimeSlotRequest = {
            id: event.resource.resource.id,
            start: startDate,
            end: endDate,
            personId: selectedPerson?.personId,
            personProfileId: selectedProfile?.personProfileId
        }

        fetcher("/timeslot", {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(timeSlotRequest)
        })
            .then(response => {
                refreshData();
            })
    }

    useEffect(() => {
        refreshData();
    }, [selectedPerson])

    const handleEventDelete = (event: ReactBigCalendarEvent) => {
        fetcher(`/timeslot/${event.resource.id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(response => {
                refreshData();
            })
    }


    const [selectedEvent, setSelectedEvent] = useState<ISelectedEvent>();

    const handleSelectSlot = useCallback(
        (e: any, selectedPerson?: Person, selectedProfile?: PersonProfile) => {
            if (!selectedPerson || !selectedProfile) {
                console.log("CANT CREATE PERSON")
                return;
            }

            // Ignore 30 minutes timeslots
            if (Math.floor(Math.abs(e.end - e.start)) / 60000 <= 30) {
                return;
            }
            const title = "New Event"
            let resource: CustomResource = new CustomResource(0)
            events.forEach(event => {
                const customResource = event.resource as CustomResource
                if (customResource.id >= resource.id) {
                    resource.id = customResource.id + 1
                }
            });

            const newEvent: ReactBigCalendarEvent = {
                start: e.start,
                end: e.end,
                title: title,
                resource: resource
            }
            handleEventCreate(newEvent, selectedPerson, selectedProfile);
        }
        , [events]
    )

    const onEventResize: withDragAndDropProps['onEventResize'] = data => {
        console.log("EVENT RESIZE");
        if (!selectedPerson || !selectedProfile) {
            console.log("CANT EDIT: NO SELECTED PERSON OR PROFILE")
            return;
        }
        const {start, end} = data;
        const {resource} = data.event
        const startDate = new Date(start.toString());
        const endDate = new Date(end.toString());

        setEvents(events.map(event => {
            if (event.resource === resource) {
                event.start = startDate;
                event.end = endDate;
                return event;
            } else {
                return event;
            }
        }));

        const event: ReactBigCalendarEvent = {
            start: startDate,
            end: endDate,
            resource: data.event,
        }

        handleEventUpdate(event);
    }

    const onEventDrop: withDragAndDropProps['onEventDrop'] = data => {
        console.log("EVENT DROP");
        const {start, end} = data;
        const {resource} = data.event

        setEvents(events.map(event => {
            if (event.resource.id === resource.id) {
                event.start = new Date(start.toString());
                event.end = new Date(end.toString());
                return event;
            } else {
                return event;
            }
        }))
    }



    const selectTimeSlot = (id: number) => {
        fetcher(`/timeslot/detail/${id}`)
            .then(response => response.json())
            .then(result => {
                const timeSlot: TimeSlot = result;
                setEditEvent(timeSlot);
            });

    }

    const onKeyPressEvent = (data: ReactBigCalendarEvent, ev: any) => {
        setSelectedEvent({calendarEvent: data, clientX: ev.clientX, clientY: ev.clientY});
    }

    const calendarMenuFunction = (type: CalendarMenuFunctionType, calendarEvent: ReactBigCalendarEvent) => {
        switch (type) {
            case CalendarMenuFunctionType.Delete:
                setSelectedEvent(undefined);
                setEvents(events.filter(event => {
                    return event.resource.id !== calendarEvent.resource.id;

                }));
                handleEventDelete(calendarEvent);
                break;

            case CalendarMenuFunctionType.Close:
                setSelectedEvent(undefined);
                break;
            case CalendarMenuFunctionType.Edit:
                setSelectedEvent(undefined);
                selectTimeSlot(calendarEvent.resource.id);
                break;

            default:
                break;
        }
    }

    const CustomDateCell= ({value,children}: DateCellWrapperProps) => {

        const now = new Date();
        now.setHours(0,0,0,0);

        return (
            <div className={ value < now ? "date-in-past" : "" }>
                { children }
            </div>
        )

    }

    const CsMessages : Messages = {

        week: 'tyden',
        day: 'den',
        today: 'dnes',
        next: 'dalsi',
        previous: 'predchozi',
        agenda: "prehled"

    }

    const renderCalendar = () => {
        if (!selectedPerson){
            return <div className={"big-calendar-blank-wrapper "}>
                <div className={"big-calendar-blank"}>Vyber osobu</div>
            </div>
        }
        return (
            <div>
                <Calendar
                    views={{
                        day: true,
                        week: true,
                        agenda: true
                    }}
                    culture={"cs"}
                    defaultView='week'
                    events={orderEvents}
                    backgroundEvents={events}
                    onSelectSlot={(e) => handleSelectSlot(e, selectedPerson, selectedProfile)}
                    localizer={localizer}
                    scrollToTime={new Date(1970, 1, 1, 6)}

                    //onEventDrop={onEventDrop}
                    //onEventResize={onEventResize}
                    onKeyPressEvent={onKeyPressEvent}
                    onDoubleClickEvent={onKeyPressEvent}
                    selectable
                    //resizable
                    style={{height: 'calc(100vh - 250px)'}}
                    step={15}
                    components={{
                        dateCellWrapper: CustomDateCell
                    }}
                    messages={CsMessages}
                />
            </div>
        )

    }


    return (
        <div className="big-calendar-wrapper">
            <div className="header">
                <h2>Casy</h2>
                <BigCalendarControls
                    selectPerson={(person: Person) => setSelectedPerson(person)}
                    selectProfile={(profile: PersonProfile) => setSelectedProfile(profile)}
                    selectedPerson={selectedPerson}
                />
            </div>
            {renderCalendar()}
            <EditForm editEvent={editEvent} setEditEvent={setEditEvent} refreshData={refreshData} />
            <CalendarEventMenu selectedEvent={selectedEvent} calendarMenuFunction={calendarMenuFunction}/>
        </div>
    )
}

export default BigCalendar;