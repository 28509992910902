import "./AdvancedLabel.scss";

export enum AdvancedLabelTypeEnum{
    Text,
    Number,
}

interface IAdvancedLabelProps{
    label: string,
    value?: string,
    suffix?: string,
    prefix?: string,
    valueType?: AdvancedLabelTypeEnum
}


const AdvancedLabel = ({label, value, suffix, prefix, valueType = AdvancedLabelTypeEnum.Text}: IAdvancedLabelProps) => {

    const convertValue = () => {
        if(!value){
            return "";
        }

        if(valueType === AdvancedLabelTypeEnum.Text){
            return value;
        }
        else if (valueType === AdvancedLabelTypeEnum.Number){

            return Number(value).toLocaleString();
        }

        return value;
    }

    return (
        <div className={"advanced-label-container"}>
            <div className={"advanced-label-label"}>
                {label}
            </div>
            <div className={"advanced-label-value"}>
                {prefix ? <span>{prefix}</span> : <></>}{convertValue()}{suffix ? <span>{suffix}</span> : <></>}
            </div>
        </div>
    )
}

export default AdvancedLabel;