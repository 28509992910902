import {
    IOrderTransactionFieldProps,
    IOrderTransactionItemProps,
    OrderTransactionType
} from "./OrderTransactionFieldModels";
import './OrderTransactionField.scss'
import React, {useEffect, useState} from "react";
import {Person} from "../../../../common/PersonSelector";
import {Dictionary} from "express-serve-static-core";
import fetcher from "../../../../tools/Fetcher";
import {Product} from "../models/ProductModels";
import {IOrderTransaction} from "../models/OrderModels";

const OrderTransactionItem = ({itemData, people, products, deleteItem}: IOrderTransactionItemProps) => {
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedPerson, setSelectedPerson] = useState<Person>();

    const getTransactionType = (): OrderTransactionType => {
        if (itemData.voucher) {
            return OrderTransactionType.Voucher;
        }
        if (itemData.reservation) {
            return OrderTransactionType.Reservation;
        }
        if (itemData.shopItem){
            return OrderTransactionType.ShopItem
        }

        return OrderTransactionType.Others;
    }

    useEffect(() => {
        if (getTransactionType() === OrderTransactionType.Reservation && itemData.reservation) {
            setSelectedProduct(itemData.reservation);
            if (itemData.person) {
                setSelectedPerson(itemData.person);
            }
        }

    }, [itemData]);


    console.log(itemData);

    let content = <></>

    const handleSelectProduct = (productId: number) => {
        if (!products) {
            return;
        }
        const product = products.find(product => product.productId === productId);
        if (!product) {
            return;
        }
        setSelectedProduct(product);
    }

    const handleSelectPerson = (personId: number) => {
        if (!people) {
            return;
        }
        const person = people.find(person => person.personId === personId);
        if (!person) {
            return;
        }
        setSelectedPerson(person);
    }

    const handleDeleteTransaction = () => {
        deleteItem(itemData);
    }

    console.warn("itemdata")
    console.log(itemData)

    if (getTransactionType() == OrderTransactionType.Reservation) {
        content = <div>
            <div>Rezervace</div>
            <div>
                <span>ID:{itemData.reservation.reservationId} | </span>
                <span>Cas:{new Date(itemData.reservation.startTime).toLocaleString()} | </span>
                <span>Delka:{itemData.reservation.duration} | </span>
                <span>Cena:{itemData.reservation.price} | </span>
                <span>Sleva:{itemData.reservation.discount}</span>
            </div>
        </div>
    }

    if (getTransactionType() == OrderTransactionType.ShopItem) {

        content = <div>
            <div>Eshop</div>
            <div>
                <span>ID:{itemData.shopItem.shopItemId} | </span>
                <span>Nazev:{itemData.shopItem.name} | </span>
                <span>Cena:{itemData.shopItem.price} | </span>
                <span>Pocet:{itemData.shopItemCount} | </span>
                <span>CenaCelkem:{Number(itemData.shopItemCount) * Number(itemData.shopItem.price)} | </span>
            </div>
        </div>
    }

    return (
        <div className={"order-transaction-field-item"}>
            <div className={"order-transaction-field-item-content"}>
                {content}
            </div>
            <div className={"order-transaction-field-item-controls"}>
                {/*<button className='btn' onClick={() => handleDeleteTransaction()}><FontAwesomeIcon icon={faTrash}/>*/}
                {/*</button>*/}
            </div>
        </div>
    )
}

const OrderTransactionField = ({data, columnTemplate, updateData}: IOrderTransactionFieldProps) => {
    const [people, setPeople] = useState<Person[]>();
    const [products, setProducts] = useState<Product[]>();
    const [orderTransactions, setOrderTransactions] = useState<IOrderTransaction[]>([]);

    const productsGetUrl = "/product";
    const peopleGetUrl = "/person";

    useEffect(() => {
        if (!data || !(columnTemplate.key in data) || !data[columnTemplate.key]) {
            setOrderTransactions([]);
            return;
        }

        setOrderTransactions(data[columnTemplate.key].map((item: Dictionary<any>) => {
            const transaction: IOrderTransaction = {
                orderTransactionID: item["orderTransactionID"],
                amount: item["amount"],
                name: item["name"],
                person: item["person"],
                reservation: item["reservation"],
                voucher: item["voucher"],
                shopItem: item["shopItem"],
                shopItemCount: item["shopItemCount"]

            }
            return transaction;
        }));

    }, [data]);

    useEffect(() => {
        fetcher(productsGetUrl)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setProducts(result.map((product: Dictionary<string>) => {
                    return {
                        name: product["name"],
                        description: product["description"],
                        imagePath: product["imagePath"],
                        price: Number(product["price"]),
                        duration: Number(product["duration"]),
                        productId: Number(product["productId"])
                    }

                }));
            });

        fetcher(peopleGetUrl)
            .then(response => response.json())
            .then(result => {
                setPeople(result.map((person: Dictionary<any>): Person => {
                    return {
                        personId: Number(person["personId"]),
                        name: `${person["firstname"]} ${person["lastname"]}`,
                        description: person["shortDescription"],
                        imagePath: person["imagePath"]
                    }

                }));
            });
    }, []);

    // const handleAddNewItem = (itemType: OrderTransactionType) => {
    //     let newTransaction: IOrderTransaction = {}
    //     if (itemType === OrderTransactionType.Reservation) {
    //         newTransaction.reservation.reservationItems[0] = products ? products[0] : undefined;
    //     } else if (itemType === OrderTransactionType.Voucher) {
    //         newTransaction.voucher = {}
    //     }
    //
    //     orderTransactions.push(newTransaction);
    //     updateData(columnTemplate.key, orderTransactions);
    // }

    const handleDeleteItem = (item: IOrderTransaction) => {
        console.log("Delete Item");
        console.log(item);
        const transactions = orderTransactions.filter(transaction => transaction.orderTransactionID != item.orderTransactionID);
        updateData(columnTemplate.key, transactions);
    }


    return (
        <div className={"order-transaction-field-container"}>
            <div className={"order-transaction-field-container-item-wrapper"}>
                {orderTransactions.map((item: any) => {
                    return <OrderTransactionItem deleteItem={handleDeleteItem} key={item.orderTransactionID} itemData={item}
                                                 people={people} products={products}/>
                })}
            </div>
        </div>
    )
}

export default OrderTransactionField;