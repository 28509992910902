import React, { ReactNode } from 'react';
import { GetText } from '../../tools/GetText';
import { Table, Modal } from 'antd';
import BaseAdminView from './common/BaseAdminView'
import { AdminTemplate, AntdTable, AntdTableColumn, Editable } from './common/BaseAdminViewModels';
import AdvancedContentGallery from "./common/AdvancedContentGallery";


function ContentGalleryAdminView() {

    return (
        <AdvancedContentGallery />
    );
}

export default ContentGalleryAdminView;
