import { IBaseAdminProps } from "./BaseAdminModels"

export enum FormComplexFieldType{
    None = 0,
    PersonProfileSelect = 1,
    OrderTransactionField = 2,
    PersonPriceOptions = 3,
    ReservationItemField = 4,
    ConsumerPaidListField = 5
}

export interface IFormComplexFieldProps extends IBaseAdminProps {
}
