import React from 'react';
import BaseAdminView from './common/BaseAdminView'
import {
    AdminTemplate,
    AntdTable,
    AntdTableColumn,
    ColumnType,
    Editable
} from './common/BaseAdminViewModels';
import {FormComplexFieldType} from "./common/baseAdminComponents/FormComplexFieldModels";
import {WorkshopTimeSlot} from "./common/models/Workshops";



function WorkshopsAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "workshopId",
                key: "workshopId",
                editable: false,
            }),
            new AntdTableColumn({
                title: "Nazev",
                dataIndex: "name",
                key: "name",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Cena",
                dataIndex: "price",
                key: "price",
                editable: true,
                componentCustomProps: {"addonAfter": "CZK"}
            }),
            new AntdTableColumn({
                title: "Zaloha",
                dataIndex: "deposit",
                key: "deposit",
                editable: true,
                componentCustomProps: {"addonAfter": "CZK"}
            }),
            new AntdTableColumn({
                title: "Casy",
                dataIndex: "timeSlots",
                key: "timeSlots",
                editable: true,
                columnType: ColumnType.MultiRangeSelector,
                render: (_:any, record: any) => {
                    if(!record.timeSlots || record.timeSlots.length === 0){
                        return <span></span>
                    }

                    return <div>
                        {record.timeSlots.map((timeSlot: WorkshopTimeSlot) => {
                            const content = <>
                                {new Date(timeSlot.startTime).toLocaleString()} - {new Date(timeSlot.endTime).toLocaleString()}
                            </>;

                            return (<div>{content}</div>)
                        })}
                    </div>
                }
            }),
            new AntdTableColumn({
                title: "Ucastnici",
                dataIndex: "attendees",
                key: "attendees",
                editable: true,
                columnType: ColumnType.FormComplexField,
                formComplexFieldType: FormComplexFieldType.ConsumerPaidListField,
                render: (_:any, record: any) => {
                    let count = 0;
                    if(record.attendees){
                        count = record.attendees.length
                    }

                    return <div>{count}</div>
                }
            }),
            new AntdTableColumn({
                title: "Kapacita",
                dataIndex: "maxCount",
                key: "maxCount",
                editable: true,
                columnType: ColumnType.Number
            }),
            new AntdTableColumn({
                title: "Obrazek",
                dataIndex: "imagePath",
                key: "imagePath",
                editable: true,
                columnType: ColumnType.Image
            }),
            new AntdTableColumn({
                title: "Aktivni",
                dataIndex: "isActive",
                key: "isActive",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
            new AntdTableColumn({
                title: "Popis",
                dataIndex: "shortDescription",
                key: "shortDescription",
                editable: true,
                columnType: ColumnType.Html,
                tableHidden: true
            }),
            new AntdTableColumn({
                title: "Dlouhy Popis",
                dataIndex: "longDescription",
                key: "longDescription",
                editable: true,
                columnType: ColumnType.Html,
                tableHidden: true
            }),
        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/workshop';
    dataTemplate.updateUrl = '/workshop';
    dataTemplate.createUrl = '/workshop';
    dataTemplate.table = tableConfiguration;
    dataTemplate.searchable = true;
    dataTemplate.headerText = "Workshopy";
    dataTemplate.editable = [Editable.Create, Editable.Edit]



    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default WorkshopsAdminView;
