import {DatePicker} from "antd";
import React from "react";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/cs_CZ'
import {start} from "repl";

interface MultiRangeSelector{
    key: string,
    start?: Date
    end?: Date
}

interface MultiRangeSelectorProps {
    dataKey: string
    updateData: Function
    data?: any
}
interface MultiRangeSelectorItemProps {
    remove: Function
    startTime?: Date
    endTime?: Date,
    update: Function
    id?: number
}


const MultiRangeSelectorItem = ({remove, update, startTime, endTime, id} : MultiRangeSelectorItemProps) => {

    const customProps = {
        "showTime": "true"
    };

    const removeDate = () => {
        remove(id);
    }

    console.log("startdate")
    console.log(startTime)

    const updateStartTime = (_: any, datestring: string) => {
        console.log(datestring);
        update(id, new Date(datestring), endTime);
    }

    const updateEndTime = (_: any, datestring: string) => {
        console.log(datestring);
        update(id, startTime, new Date(datestring));
    }

    // @ts-ignore
    const startDatePicker = <DatePicker locale={locale} value={dayjs(startTime)} onChange={updateStartTime} {...customProps} />
    // @ts-ignore
    const endDatePicker = <DatePicker locale={locale} value={dayjs(endTime)} onChange={updateEndTime} {...customProps} />
    return (
        <div className={"multi-range-selector-item"}>
            <div className={"multi-range-selector-item-date"}>
                <DatePicker.RangePicker locale={locale}  showTime={true} />
                <div>Zacatek {startDatePicker}</div>
                <div>Konec {endDatePicker}</div>
            </div>
            <div className={"multi-range-selector-item-controls"}>
                <button className={"btn"} type={"button"} onClick={removeDate}>Odebrat</button>
            </div>
        </div>
    )
}

const MultiRangeSelector = ({dataKey, updateData, data} : MultiRangeSelectorProps) => {


    const add = () => {
        let arr = data[dataKey];
        if (!arr || arr.length === 0){
            arr = [{id: -1, startTime: undefined, endTime: undefined}]
        }
        else{
            const lowest = arr.reduce((min: any,dat: any) => min.id < dat.id ? min : dat);
            arr.push({id: lowest.id - 1, startTime: undefined, endTime: undefined})
        }
        updateData(dataKey, arr);
    }

    const update = (id: number, startTime: Date, endTime: Date) => {
        const arr = data[dataKey];
        let index = arr.findIndex(((obj: any) => obj.id == id));
        arr[index] = {id: id, startTime: startTime, endTime: endTime};
        updateData(dataKey, arr);
    }

    const remove = (id: number) => {
        const arr = data[dataKey];
        const newArr = arr.filter((dat: any) => dat.id != id);
        updateData(dataKey, newArr);
    }

    return (<div className={"multi-range-selector-wrapper"}>
        <div className={"multi-range-selector-add"}>
            <button className={"btn"} type={"button"} onClick={() => add()}>Pridat</button>
        </div>
        {data && dataKey in data && data[dataKey].map((item: any) => {
            return <MultiRangeSelectorItem key={item.id} update={update} id={item.id} startTime={item.startTime} endTime={item.endTime} remove={remove}/>;
        })}

    </div>)
}

export default MultiRangeSelector;