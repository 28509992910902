import React from "react";
import "./LoadingBar.scss"

export type LoadingBarProps = {
    show: boolean
}

export const LoadingBar = ({show}: LoadingBarProps) => {
    if(!show){
        return <></>
    }

    return(
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
}