import React from 'react';
import BaseAdminView from './common/BaseAdminView'
import {AdminTemplate, AntdTable, AntdTableColumn, ColumnType, Editable} from './common/BaseAdminViewModels';
import {FormComplexFieldType} from './common/baseAdminComponents/FormComplexFieldModels';


function ProductsAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "personId",
                key: "personId",
                hiddenInput: true,
                tableHidden: true
            }),
            new AntdTableColumn({
                title: "Jmeno",
                dataIndex: "firstname",
                key: "firstname",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Prijmeni",
                dataIndex: "lastname",
                key: "lastname",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Obrazek",
                dataIndex: "imagePath",
                key: "imagePath",
                editable: true,
                columnType: ColumnType.Image
            }),
            new AntdTableColumn({
                title: "Aktivni",
                dataIndex: "isActive",
                key: "isActive",
                editable: true,
                columnType: ColumnType.Checkbox
            }),
            new AntdTableColumn({
                title: "Hodnost",
                dataIndex: "personRank",
                key: "personRank",
                editable: true,
                columnType: ColumnType.Select,
                dataSourceUrl: "/person/rank",
                selectMapLabel: "name",
                selectMapValue: "personRankId",
                 //componentCustomProps: {"mode": "multiple"}
            }),
            new AntdTableColumn({
                title: "Poradi",
                dataIndex: "displayOrder",
                key: "displayOrder",
                editable: true,
                columnType: ColumnType.Number
            }),
            new AntdTableColumn({
                title: "Profily",
                dataIndex: "personProfiles",
                key: "personProfiles",
                editable: true,
                columnType: ColumnType.FormComplexField,
                formComplexFieldType: FormComplexFieldType.PersonProfileSelect,
                dataSourceUrl: '/person/rank',
                tableHidden: true
            }),
            new AntdTableColumn({
                title: "Popis",
                dataIndex: "shortDescription",
                key: "shortDescription",
                editable: true,
                columnType: ColumnType.TextArea
            }),
        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/person';
    dataTemplate.updateUrl = '/person';
    dataTemplate.createUrl = '/person';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Cloveci";
    dataTemplate.editable = [Editable.Create, Editable.Edit]
    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default ProductsAdminView;
