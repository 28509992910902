import React, { ReactNode } from 'react';
import { GetText } from '../../tools/GetText';
import { Table, Modal } from 'antd';
import BaseAdminView from './common/BaseAdminView'
import {AdminTemplate, AntdTable, AntdTableColumn, ColumnType, Editable} from './common/BaseAdminViewModels';



function UserAdminView() {

    let tableConfiguration: AntdTable = {
        columns: [
            new AntdTableColumn({
                title: "Id",
                dataIndex: "userId",
                key: "userId",
                hiddenInput: true
            }),
            new AntdTableColumn({
                title: "Name",
                dataIndex: "name",
                key: "name",
                editable: true,
            }),
            new AntdTableColumn({
                title: "Role",
                dataIndex: "role",
                key: "role",
                editable: true,
                columnType: ColumnType.Select,
                dataSourceUrl: "/user/roles",
                selectMapLabel: "name",
                selectMapValue: "roleId",
            }),
            new AntdTableColumn({
                title: "Aktivni",
                dataIndex: "isActive",
                key: "isActive",
                editable: true,
                columnType: ColumnType.Checkbox
            })


        ]
    }

    let dataTemplate: AdminTemplate = new AdminTemplate();
    dataTemplate.getUrl = '/user';
    dataTemplate.updateUrl = '/user';
    dataTemplate.createUrl = '/user';
    dataTemplate.table = tableConfiguration;
    dataTemplate.headerText = "Uzivatele";
    dataTemplate.editable = [Editable.Create, Editable.Edit]
    
    return (
        <BaseAdminView template={dataTemplate}/>
    );
}

export default UserAdminView;
