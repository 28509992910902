import {AdvancedCheckbox} from "./dashboardComponents/common/AdvancedCheckbox";
import ContentGallery, {ContentModel} from "./ContentGallery";
import React, {useState, useEffect, ChangeEvent} from "react";
import {Settings} from "./models/SettingModels";
import fetcher from "../../../tools/Fetcher";

export const ContentModalEditor = () => {
    const [showImageGallery, setShowImageGallery] = useState<boolean>(false);
    const [settings, setSettings] = useState<Settings[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showGallery, setShowGallery] = useState<boolean>(false);

    const requiredSettings = ["ModalImage", "ModalCloseColor", "ModalActive"]

    const getSettingsValue = (key: string, defaultValue: any = "") => {
        const value = settings.find(item => {
           if(item.key === key){
               return item;
           }
        });
        console.log(value)
        console.log(value ? value.value : defaultValue);
        return value ? value.value.toString() : defaultValue;
    }

    useEffect(() => {
        fetcher('/setting')
            .then(response => response.json())
            .then(result => {
                setSettings(result.filter((setting: Settings) => {
                    if(requiredSettings.includes(setting.key)){
                        return setting;
                    }
                }));
            })
    }, []);

    const selectContentItem = (value: ContentModel, confirmToken: boolean) => {
        setShowImageGallery(false);
        if (!confirmToken) {
            return;
        }

        handleUpdateValue("ModalImage", `images/${value.getImagePath()}`)
    }

    const handleUpdateValue = (key: string, newValue: string) => {
        const settingsModel: Settings = {
            key: key,
            value: newValue.toString()
        }

        setIsLoading(true);
        fetcher('/setting', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(settingsModel)
        })
            .then(response => {
                if(response.status === 200) {
                    setSettings(settings.map(item => {
                        if (item.key === key) {
                            return {...item, value: newValue}
                        }
                        return item;
                    }))
                }
                setIsLoading(false);
                console.log(response.status);
            })
    }

    const handleSelectContentItem = () => {

    }

    const handleUpdateColor = (element: ChangeEvent<HTMLInputElement>) => {
        const { value } = element.target;
        handleUpdateValue("ModalCloseColor", value);
    }


    const renderImagePicker = () => {
        if(showImageGallery) {
            return (
                <div>
                    <ContentGallery
                        selectContent={(value: ContentModel, confirmToken: boolean) => selectContentItem(value, confirmToken)}
                    />
                </div>
            )
        }
        return (
            <div>
                <div>
                    <button className={"btn"} onClick={() => setShowImageGallery(true)}>Vybrat obrazek</button>
                </div>
                <div>
                    <img alt={"Neznamy obrazek"} src={getSettingsValue("ModalImage")} className={"modal-image"} />
                </div>

            </div>
        )
    }

    return (
        <div className={"content-modal-editor"}>
            <h2>Vyskakovaci okno</h2>
            <div className={"content-modal-controls"}>
                <div className={"content-modal-controls-item"}>
                    <AdvancedCheckbox
                        label={"Povoleno"}
                        value={getSettingsValue("ModalActive").toLowerCase() === "true"}
                        updateFunction={(value: string) => handleUpdateValue("ModalActive", value)}
                        disabled={isLoading}
                    />
                </div>

                <div className={"content-modal-controls-item"}>
                    <input
                        type="color"
                        value={getSettingsValue("ModalCloseColor", "#000000")}
                        onChange={handleUpdateColor}
                        disabled={isLoading}
                    />
                    <label>Barva krizku</label>
                </div>

            </div>
            {renderImagePicker()}
        </div>
    )
}