import {IBaseAdminProps} from "./BaseAdminModels";
import React from "react";
import {Select} from "antd";

export interface IConsumerPaidListField extends IBaseAdminProps {

}

export interface IConsumer {
    id: number
    name?: string
    email?: string
    phone?: string
}

interface ConsumerPaidListFieldItemProps{
    consumer?: IConsumer
    remove: Function
}

const AttendeePaidStatus: any = [
    {
        "value": 0,
        "label": "Registrovan"
    },
    {
        "value": 1,
        "label": "Zaloha zaplacena"
    },
    {
        "value": 2,
        "label": "Zaplaceno"
    },
    {
        "value": 3,
        "label": "Zruseno"
    },
]

const ConsumerPaidListFieldItem = ({consumer, remove}: ConsumerPaidListFieldItemProps) => {
    const removeDate = () => {
        remove(consumer?.id);
    }

    return (
        <div className={"consumer-paid-list-item"}>
            <div className={"consumer-paid-list-info"}>
                <div>{consumer?.name}</div>
                <div>{consumer?.phone}</div>
                <div>
                    <Select options={AttendeePaidStatus} />
                </div>
            </div>
            <div className={"consumer-paid-list-item-controls"}>
                <button className={"btn"} type={"button"} onClick={removeDate}>Odebrat</button>
            </div>
        </div>
    )
}

const ConsumerPaidListField = ({data, columnTemplate, updateData}: IConsumerPaidListField) => {

    const remove = (id: number) => {
        const arr = data[columnTemplate.key];
        const newArr = arr.filter((dat: any) => dat.id != id);
        updateData(columnTemplate.key, newArr);
    }

    return (
        <div className={"consumer-paid-list"}>
            {data && columnTemplate.key in data && data[columnTemplate.key].map((item: any) => {
               return <ConsumerPaidListFieldItem remove={remove} consumer={
                   {
                       id: item.consumer.consumerId,
                       name: item.consumer.firstName + item.consumer.lastName,
                       email: item.consumer.email,
                       phone: item.consumer.phone
                   }
               }/>
            })}
        </div>
    )
}

export default ConsumerPaidListField;