import {Event as ReactBigCalendarEvent } from 'react-big-calendar';
import { PersonProfile } from './baseAdminComponents/PersonProfileSelect';
import { Person } from '../../../common/PersonSelector';

export interface ISelectedEvent {
    calendarEvent: ReactBigCalendarEvent,
    clientX: number,
    clientY: number
}

export enum CalendarMenuFunctionType {
    Delete = 1,
    Edit = 2,
    Close = 3
}

export interface CalendarEventMenuProps {
    selectedEvent?: ISelectedEvent,
    calendarMenuFunction: Function
}

export class CustomResource {
    id: number = 0;
    showMenu: boolean = false;

    public constructor(id: number){
        this.id = id;
    }
}

export interface ITimeSlotRequest{
    id?: number;
    start: Date;
    end: Date;
    personId?: number;
    personProfileId?: number
}

export interface IBigCalendarControlsProps{
    selectedPerson?: Person
    selectPerson: Function,
    selectProfile: Function
}